<div *ngIf="isSubmitted">
  <div class="">
    <div class="alert alert-primary" role="alert">
      Su solicitud fue enviada con éxito.
    </div>
  </div>
</div>

<div *ngIf="!isSubmitted">
  <h2 class="title-secondary">Póngase en contacto con nuestro equipo</h2>

  <form *ngIf="serviceLinesForm" [formGroup]="serviceLinesForm" (ngSubmit)="onSubmit()" id="serviceLinesForm">
    <div class="contact-our-team">
      <div class="row">
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="firstNameLabel" class="weight-400">Nombre&thinsp;*</label>
            <input maxlength="40" id="firstName" formControlName="firstName" class="form-control" />
            <div *ngIf="(firstNameValid.invalid && firstNameValid.touched)" class="alert alert-danger">
              <div *ngIf="firstNameValid.errors?.required || firstNameValid.touched">
                Proporcione un Nombre
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="lastNameLabel">Apellido&thinsp;*</label>
            <input maxlength="40" id="lastName" formControlName="lastName" class="form-control" />
            <div *ngIf="(lastNameValid.invalid && lastNameValid.touched)" class="alert alert-danger">
              <div *ngIf="lastNameValid.errors?.required || lastNameValid.touched">
                Proporcione un Apellido
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="emailLabel">Correo electrónico&thinsp;*</label>
            <input type="email" maxlength="80" id="email" formControlName="email" class="form-control" />
            <div *ngIf="(emailValid.invalid && emailValid.touched)" class="alert alert-danger">
              <div *ngIf="emailValid.errors?.required || emailValid.touched">
                Proporcione una dirección de correo electrónico
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="companyLabel">Empresa&thinsp;*</label>
            <input maxlength="40" id="company" formControlName="company" class="form-control" />
            <div *ngIf="(companyValid.invalid && companyValid.touched)" class="alert alert-danger">
              <div *ngIf="companyValid.errors?.required || companyValid.touched">
                Proporcione una empresa
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="countryLabel">País</label>
            <select id="country" formControlName="country" class="form-control state-field form-select"
              (change)="selectCountry($event)">
              <option *ngFor="let c of country; let i = index" [value]="country[i].code">{{country[i].name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="stateLabel">Estado</label>
            <select id="state" formControlName="state" class="form-control state-field form-select">
              <option *ngFor="let s of state; let i = index" [value]="state[i].code">{{state[i].name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="phoneLabel">Número de teléfono</label>
            <input maxlength="40" id="phone" formControlName="phone" class="form-control" />
          </div>
        </div>
        <div class="col-md-6" id="infoNotesGroup">
          <div class="sl-form-group">
            <label id="infoNotesLabel">Additional information</label>
            <input type="text" id="infoNotes" class="form-control" #infoNotes />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12" id="recaptchaElem">
          <ngx-recaptcha2 #captchaElem theme="white" hl="es" siteKey="{{siteKey}}" formControlName="recaptcha"
            id="recaptcha" (success)="handleSuccess($event)"></ngx-recaptcha2>
        </div>
      </div>

      <p>*Indica un campo obligatorio</p>

      <div class="row">
        <div class="col-12 text-center mb-0">
          <button type="submit" id="submitButton" class="btn btn-secondary sbutton-disabled"
            [disabled]="!serviceLinesForm.valid">Enviar</button>
        </div>
      </div>
    </div>
  </form>
  <div id="redirect"></div>
</div>


<script src='https://www.google.com/recaptcha/api.js?hl=es-419'></script>