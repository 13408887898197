import { Pipe, PipeTransform } from '@angular/core';

@
Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {

    if (value)
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    return '';
    
  }
  
}
