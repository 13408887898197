import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StockQuoteService {

  constructor(private http: HttpClient) { }
    
    public getQuote() {
      return this.http.get(window.location.origin + '/api/stockquote/schn');
    }

    public getQuoteByDate(date:Date) {
      return this.http.get(window.location.origin + '/api/stockquote/schn/' + date.toISOString().slice(0, 10));
    }
  
}
