import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-sp',
  templateUrl: './footer-sp.component.html',
  styleUrls: ['./footer-sp.component.css']
})
export class FooterSpComponent {
  copyDate = Date.now();
}
