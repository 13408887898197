<div style="font-family:'Inter', sans-serif; position: relative; z-index:100; background:#fff; padding:15px;">
    <header class="text-center">
      <img src="/assets/images/logo.svg" style="max-width:300px; margin:30px 0;" alt="Radius Recycling" />
    </header>
    <div class="h-100 text-center">
      <div>
        <h1>{{data.header}}</h1>
        <p> {{data.p1}} </p>
        <p>{{data.p2}} <a (click)="onLinkClick()" class="linkhover"><b>{{data.sitemapLinkName}}</b></a>.</p>
        <a [routerLink]="'/'" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><path d="m498.195 222.695-.035-.035L289.305 13.813C280.402 4.905 268.566 0 255.977 0c-12.59 0-24.426 4.902-33.332 13.809L13.898 222.55c-.07.07-.14.144-.21.215-18.282 18.386-18.25 48.218.09 66.558 8.378 8.383 19.445 13.238 31.277 13.746.48.047.965.07 1.453.07h8.324v153.7C54.832 487.254 79.578 512 110 512h81.71c8.282 0 15-6.715 15-15V376.5c0-13.879 11.29-25.168 25.169-25.168h48.195c13.88 0 25.168 11.29 25.168 25.168V497c0 8.285 6.715 15 15 15h81.711c30.422 0 55.168-24.746 55.168-55.16v-153.7h7.719c12.586 0 24.422-4.902 33.332-13.808 18.36-18.371 18.367-48.254.023-66.637zm0 0" fill="#ffffff" data-original="#ffffff" class="" opacity="1"></path></svg> <span>{{data.homeButton}}</span></a>
      </div>
    </div>
    <div class="text-center footer">&copy;2023 Schnitzer Steel Industries, Inc <i>dba</i> Radius Recycling.</div>
  </div>