import { Component, OnInit, ViewEncapsulation,ViewChild, ElementRef } from '@angular/core';
import { HreflangService } from '../shared/service/hreflang.service';
import { Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { InvestorNewsService } from '../shared/service/investor-news.service';
import { InvestorEventsService } from '../shared/service/investor-events.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  newsFeeds: Observable<any[]>;
  newsItems: any = [];
  inError = false;
  pageCSSId: any = 'home_page';
  playCheck:any=false;
  @ViewChild("videoPlayer", { static: true }) videoplayer!: ElementRef;

  constructor(private hreflangService: HreflangService, protected newsService: InvestorNewsService, protected eventsService: InvestorEventsService,public modalService: NgxSmartModalService) {

  }
  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    margin:30,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  }
  ngOnInit() {

    this.hreflangService.createLinkHreflang('/');

    this.newsService.GetNews(1).subscribe(newsFeed => this.displayNews(newsFeed), err => this.newsDataError(err));

  }
  
  displayNews(news: any) {

    for (let i = 0; i < 5; i++) {
      const newsItem: any = {};
      newsItem.id = news[i].id;
      newsItem.date = news[i].releaseDate.date;
      newsItem.title = news[i].title;
      this.newsItems.push(newsItem);
    }
  }

  newsDataError(err: any) {
    this.inError = true;
  }

  playPause() {
    const video =this.videoplayer.nativeElement;
    video.currentTime=0;
    video.load();
    video.play();
  }
  openModal(name:string){
    this.modalService.getModal(name).open()
  }
}
