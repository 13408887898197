<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-lg-3">
        <b class="d-block pb-2"><img src="assets/images/logo-white.svg" class="fot-logo" alt="Radius Recycling" /></b>
        <p>299 SW Clay Street, Suite 400<br>Portland, OR<br>
          <a [routerLink]="'/compania/contactos'">Contactos</a>
        </p>
        <div class="follow-us">
          <a href="https://www.linkedin.com/company/radius-recycling/" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a>
          <a href="https://www.youtube.com/@radiusrecycling" target="_blank" class="youtube"><i class="fa-brands fa-youtube"></i></a>
        </div>
      </div>
      <div class="col-md-8 col-lg-9">
        <div class="row">
          <div class="col-md-6 col-lg-3 my-3 my-md-0">
            <h6 class="font-anton"><a [routerLink]="'/compania'">Acerca de</a></h6>
            <ul>
              <li><a [routerLink]="'/compania'">Empresa</a></li>
              <li><a [routerLink]="'/compania/cultura'">Cultura</a></li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-5 my-3 my-md-0">
            <h6 class="font-anton"><a [routerLink]="'/compania/carreras'">Carreras profesionales</a></h6>
            <ul>
              <li><a [routerLink]="'/compania/carreras'">Descripción general</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row footer-text">
      <div class="copyright col-md-6">&copy;{{copyDate | date: 'yyyy'}} Radius Recycling, Inc.</div>
      <div class="copyright col-md-6">
        <ul class="footer-nav inline-nav">
          <li><a [routerLink]="'/mapa-del-sitio'">Mapa Del Sitio</a></li>
          <li><a [routerLink]="'/compania/etica'">Ética</a></li>
          <li><a [routerLink]="['/avisos-legales']">Términos</a></li>
          <li class="is-last"><a [routerLink]="['/avisos-legales']" fragment="privacidad">Privacidad</a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
