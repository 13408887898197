<!--<div id="sidebar_box" class="right_col full-on-sm" style="margin-top: 0; margin-bottom: 5px;">-->

  <div [className]="Theme">
    <h2>{{txt.title}} <span>{{txt.titleorange}}</span><br>{{txt.titlesub}}</h2>
   
        <div class="position-relative">
        <input class="form-control" [(ngModel)]="PostalCode" type="text" name="Address" (keyup.enter)="getLocationSearchLink()" placeholder="{{txt.ph}}" />
        <p *ngIf="isPostalCode" class="text-danger">{{txt.pleaseEnterAddress}}</p>
        <button  id="location-search-button" class="btn btn-secondary" (click)="getLocationSearchLink()" ><i class="fa-solid fa-magnifying-glass"></i></button>
        </div>
      
      <div><button [routerLink]="locationLink()" fragment="locationList" id="location-search-button" class="btn btn-secondary">{{txt.search}}</button></div>
    </div>
