import { environment } from '../../../environments/environment';

export class UrlDecider {

  // Returns eng or es based on url
  static getLangFromUrl(): string {
    const sslang = window.localStorage.getItem('sslang');
    if (environment.languageSettings.engHost === 'localhost' &&
      window.location.href.endsWith(environment.languageSettings.engPath)) {
      window.localStorage.setItem('sslang', 'eng');
      return 'eng';
    } else if (environment.languageSettings.esHost === 'localhost' &&
      window.location.href.endsWith(environment.languageSettings.esPath)) {
      window.localStorage.setItem('sslang', 'es');
      return 'es';
    } else if (sslang) {
      return sslang;
    } else if (window.location.origin.indexOf('localhost') > -1) { // default to eng for localhost
      return 'eng';
    } else if (window.location.origin.indexOf(environment.languageSettings.esHost) > -1) {
      return 'es';
    } else {
      return 'eng';
    }

  }

  static getSpanishURL(): string {
    return (environment.languageSettings.esHost === 'localhost')
      ? `${window.location.origin}${environment.languageSettings.esPath}`
      : `${environment.languageSettings.esHost}${environment.languageSettings.esPath}`;
  }

  static getEnglishURL(): string {
    return (environment.languageSettings.engHost === 'localhost')
      ? `${window.location.origin}${environment.languageSettings.engPath}`
      : `${environment.languageSettings.engHost}${environment.languageSettings.engPath}`;
  }

}
