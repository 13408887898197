<header>
  <div class="top-nav container">
    <ul>
      <li><a [routerLink]="'/company/sustainability'" (click)="isNavbarOpen=false">Sustainability</a></li>
      <li><a [routerLink]="'/company/investors'" (click)="isNavbarOpen=false">Investors</a></li>
      <li><a [routerLink]="'/company/careers'" (click)="isNavbarOpen=false">Careers</a></li>
      <li><a [routerLink]="'/company/contacts'" (click)="isNavbarOpen=false">Contact Us</a></li>
      <li class="is-last"><a href="{{linkSpanish}}">Español</a></li>
    </ul>
  </div>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="'/'"><img src="assets/images/logo.svg" alt="" /></a>
      <button class="navbar-toggler" type="button" (click)="isNavbarOpen=!isNavbarOpen">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngClass]="{ 'show': isNavbarOpen }">
        <ul class="navbar-nav ms-auto mb-lg-0">
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" (click)="onMenuToggle('isAboutUsMenu')">About
              Us</a>
            <ul class="dropdown-menu" [ngClass]="{ 'show': navMenuToggle.isAboutUsMenu }">
              <li><a [routerLink]="'/company'" (click)="navMenuToggle.isAboutUsMenu=false;isNavbarOpen=false;">Our Company</a></li>
              <li><a [routerLink]="'/company/about-radius-recycling/management'" (click)="afterMenuToggle('isAboutUsMenu')">Leadership </a></li>
              <li><a [routerLink]="'/company/about-radius-recycling/history'" (click)="afterMenuToggle('isAboutUsMenu')">History</a></li>
              <li><a [routerLink]="'/company/about-radius-recycling/culture'" (click)="afterMenuToggle('isAboutUsMenu')">Culture</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" [routerLink]="'/locations'">Locations</a>
          </li>
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle"
              (click)="onMenuToggle('isSellToUsMenu')">Sell To Us</a>
            <ul class="dropdown-menu" [ngClass]="{ 'show': navMenuToggle.isSellToUsMenu }">
              <li><a [routerLink]="'/sell-to-us'" (click)="afterMenuToggle('isSellToUsMenu')">Accepted Materials</a></li>
              <li><a [routerLink]="'/locations'" (click)="afterMenuToggle('isSellToUsMenu')">Our Locations</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle"
              (click)="onMenuToggle('isBuyFromUsMenu')">Buy From Us</a>
            <ul class="dropdown-menu" [ngClass]="{ 'show': navMenuToggle.isBuyFromUsMenu }">
              <li><a [routerLink]="'/buy-from-us'" (click)="afterMenuToggle('isBuyFromUsMenu')">Our Products </a></li>
            </ul>
          </li>
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle"
              (click)="onMenuToggle('isServicesMenu')">Services</a>
            <ul class="dropdown-menu dropdown-menu-end" [ngClass]="{ 'show': navMenuToggle.isServicesMenu }">
              <li class="dropdown">
                <a [routerLink]="'/service-lines/vehicle-recycling-services'" (click)="afterMenuToggle('isServicesMenu')">3PR<sup>TM</sup> Third Party Recycling</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
