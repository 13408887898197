import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HomeSpComponent } from './home-sp/home-sp.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { LegalNoticesComponent } from './legal-notices/legal-notices.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GlobalErrorHandler } from './shared/lib/global-error-handler';
import { MapaDelSitioComponent } from './mapa-del-sitio/mapa-del-sitio.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
//import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { CeoOfficeHoursComponent } from './ceo-office-hours/ceo-office-hours.component';
import { LegalNoticesSpComponent } from './legal-notices-sp/legal-notices-sp.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeSpComponent,
    LegalNoticesComponent,
    SitemapComponent,
    DataPrivacyComponent,
    MapaDelSitioComponent,
    CeoOfficeHoursComponent,
    LegalNoticesSpComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule, NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxCaptchaModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgxSmartModalModule.forRoot()
    //NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsKey),
    //NgxGoogleAnalyticsRouterModule
  ],
  providers: [DatePipe,NgxSmartModalService, DecimalPipe, { provide: ErrorHandler, useClass: GlobalErrorHandler }],
  bootstrap: [AppComponent],
  exports: []

})
export class AppModule { }
