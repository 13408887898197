import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceLinesService {

  constructor(private http: HttpClient) { }

  getCountries() {
    return this.http.get(window.location.origin + '/api/servicelines/country');
  }

  getStatesByCountry(country: any) {
    return this.http.get(window.location.origin + '/api/servicelines/states/' + country);
  }

  postServiceLinesForm(form: any) {

    return this.http.post(window.location.origin + '/api/servicelines/form/', form);
    //return this.http.get('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8?' + form);
  }

}
