import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { OfficeHoursService } from '../shared/service/office-hours.service';

@Component({
  selector: 'app-ceo-office-hours',
  templateUrl: './ceo-office-hours.component.html',
  styleUrls: ['./ceo-office-hours.component.css']
})
export class CeoOfficeHoursComponent implements OnInit {

  isSubmitted = false;

  constructor(private officeHoursService: OfficeHoursService) { }

  ngOnInit(): void {
  }

  officeHours = new UntypedFormGroup({
    employeeNumber: new UntypedFormControl('', [Validators.required]),
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl(''),
    phone: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required])
  });

  get employeeNumberValid() {
    return this.officeHours.get('employeeNumber');
  }

  get firstNameValid() {
    return this.officeHours.get('firstName');
  }

  get lastNameValid() {
    return this.officeHours.get('lastName');
  }

  get emailValid() {
    return this.officeHours.get('email');
  }
  get phoneValid() {
    return this.officeHours.get('phone');
  }

  onSubmit() {
    this.officeHours.markAllAsTouched();

    if (!this.officeHours.valid) {
      return;
    }

    this.isSubmitted = true;

    this.officeHoursService.addUser(this.officeHours.value).subscribe((data: any) => {
      this.officeHours = (data as any);
    });

  }

}
