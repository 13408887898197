import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';

import { NavMainComponent } from './component/nav-main/nav-main.component';
import { NavMainSpComponent } from './component/nav-main-sp/nav-main-sp.component'


import { SimpleStockTickerComponent } from './component/simple-stock-ticker/simple-stock-ticker.component';
import { Rot13DecryptPipe } from './pipe/rot13encryption/rot13-decrypt.pipe';
import { FooterComponent } from './component/footer/footer.component';
import { FormatMoneyPipe } from './pipe/format-money.pipe';
import { FormatNumberPipe } from './pipe/format-number.pipe';
import { LocationSearchComponent } from './component/location-search/location-search.component';
import { FooterSpComponent } from './component/footer-sp/footer-sp.component';
import { ServiceLinesFormComponent } from './component/service-lines-form/service-lines-form.component';
import { ServiceLinesFormSpComponent } from './component/service-lines-form-sp/service-lines-form-sp.component';


@NgModule({
  declarations: [NavMainComponent, NavMainSpComponent, SimpleStockTickerComponent, Rot13DecryptPipe, FooterComponent, FormatMoneyPipe, FormatNumberPipe, LocationSearchComponent, FooterSpComponent, ServiceLinesFormComponent, ServiceLinesFormSpComponent],
  imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      NgxCaptchaModule
    ],
    exports: [
        NavMainComponent,
        NavMainSpComponent,
        FooterComponent,
        SimpleStockTickerComponent,
        LocationSearchComponent,
        FooterSpComponent,
        Rot13DecryptPipe,
        FormatMoneyPipe,
        FormatNumberPipe,
        ServiceLinesFormComponent,
        ServiceLinesFormSpComponent,
    ]
})
export class SharedModule { }
