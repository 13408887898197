import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-notices-sp',
  templateUrl: './legal-notices-sp.component.html',
  styleUrls: ['./legal-notices-sp.component.css']
})
export class LegalNoticesSpComponent {
  emailDataPriv = 'qngncevinpl@fpua.pbz';
}
