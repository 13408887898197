<!-- Mobile menu site overlay see pushy.js -->

<div #banner *ngIf="env === 'development'" id="localBanner" class="localhost-env-banner">
  <div class="env-text">This is the localhost environment</div>
  <div><a class="console-link-white" href="/console">Go to console</a>&nbsp;|&nbsp;</div>
  <div class="dismiss-div" (click)="dismissBanner()">Click to dismiss</div>
</div>

<div #banner *ngIf="env === 'azuredev'" id="devBanner" class="dev-env-banner">
  <div class="env-text">This is the Azure dev environment</div>
  <div><a class="console-link-black" href="/console">Go to console</a>&nbsp;|&nbsp;</div>
  <div class="dismiss-div" (click)="dismissBanner()">Click to dismiss</div>
</div>

<div #banner *ngIf="env === 'qa'" id="qaBanner" class="qa-env-banner">
  <div class="env-text">This is the Azure qa environment</div>
  <div><a class="console-link-white" href="/console">Go to console</a>&nbsp;|&nbsp;</div>
  <div class="dismiss-div" (click)="dismissBanner()">Click to dismiss</div>
</div>

<div class="container">
  <div *ngIf="devRouteError" class="card text-white bg-danger mb-3" style="margin: 40px 20px 40px 20px">
    <div class="card-header">Routing Error - Shown only in dev mode</div>
    <div class="card-body" style="background-color: white; color: black; font-size: x-large">
      <p class="card-text">{{ devRouteError }}</p>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>

<div class="site-overlay" style="margin-left: 300px"></div>
<router-outlet></router-outlet>
