import { Component, OnInit } from '@angular/core';
import { StockQuoteService } from '../../service/stockquote.service';

@Component({
  selector: 'app-simple-stock-ticker',
  templateUrl: './simple-stock-ticker.component.html',
  styleUrls: ['./simple-stock-ticker.component.css']
})
export class SimpleStockTickerComponent implements OnInit {

  quoteModel:any;

    constructor(private stockQuoteService: StockQuoteService) {}

  ngOnInit() {

      this.stockQuoteService.getQuote().subscribe((qm: any) => {
          this.quoteModel = qm[0];
      });

  }

}
