import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-notices',
  templateUrl: './legal-notices.component.html',
  styleUrls: ['./legal-notices.component.css']
})
export class LegalNoticesComponent {
  emailDataPriv = 'qngncevinpl@fpua.pbz';
}
