import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { first } from 'rxjs/operators';

import { ConfigService } from '../../../shared/service/config.service';
import { ServiceLinesService } from '../../../shared/service/service-lines.service';

@Component({
  selector: 'app-service-lines-form-sp',
  templateUrl: './service-lines-form-sp.component.html',
  styleUrls: ['./service-lines-form-sp.component.css']
})
export class ServiceLinesFormSpComponent implements OnInit {

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  @ViewChild('infoNotes') infoNotes: ElementRef;

  public readonly siteKey = '6LevUxIaAAAAACH0xH0NqBtdOcaBxL3LgLnAxBCx';

  constructor(private serviceLinesService: ServiceLinesService, private configService: ConfigService) {

    this.getCountries();
  }

  firstName: any;
  lastName: any;
  email: any;
  company: any;
  country: any;
  state: any = [];
  phone: any;
  emailValidPattern: any = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';


  @Input() pageName: any;
  @Input() specificLeadSource: any;
  @Input() supplyChannel: any;
  @Input() businessType: any;
  @Input() gaOnclickFunction: any;

  isRecaptchaValid = false;
  isSubmitted = false;

  get firstNameValid() {
    return this.serviceLinesForm.get('firstName');
  }
  get lastNameValid() {
    return this.serviceLinesForm.get('lastName');
  }
  get emailValid() {
    return this.serviceLinesForm.get('email');
  }
  get companyValid() {
    return this.serviceLinesForm.get('company');
  }
  get recaptcha() {
    return this.serviceLinesForm.get('recaptcha');
  }


  serviceLinesForm = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required]),
    lastName: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.emailValidPattern)]),
    company: new UntypedFormControl('', [Validators.required]),
    phone: new UntypedFormControl(''),
    country: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    recaptcha: new UntypedFormControl('', Validators.required)
  });

  ngOnInit(): void {
    this.state.push({ ['code']: '', ['name']: 'Elija un país primero' });
  }

  handleSuccess(response: any) {
    this.serviceLinesForm.markAllAsTouched();
    this.isRecaptchaValid = true;
  }

  handleError() {
    this.isRecaptchaValid = false;
  }

  handleExpire() {
    this.isRecaptchaValid = false;
  }

  onSubmit() {

    this.serviceLinesForm.markAllAsTouched();

    if (!this.serviceLinesForm.valid) {
      return;
    }

    if (this.infoNotes.nativeElement.value.length > 0) {
      return;
    }

    const dto = this.serviceLinesForm.value;
    dto['leadSource'] = 'Website';
    dto['Oid'] = '00D460000000sZp';
    dto['RetURL'] = 'http://';
    dto['specificLeadSource'] = this.specificLeadSource;
    dto['supplyChannel'] = this.supplyChannel;
    dto['businessType'] = this.businessType;
    dto['RecaptchaChallenge'] = this.recaptcha?.value;

    this.isSubmitted = true;

    this.serviceLinesService.postServiceLinesForm(dto).pipe(first()).subscribe((data: any) => {
      console.log(data);
    }, error => {
      return;
    });

  }

  getCountries() {
    const countries = this.serviceLinesService.getCountries().subscribe(countries => {
      this.country = countries;
      this.country.unshift({ ['code']: '', ['name']: 'Elija un país' });
      this.serviceLinesForm.controls.country.patchValue(this.country[0].code);
    });
    return countries;
  }

  selectCountry(event: any) {
    const country = event.target.value;
    this.getStatesByCountry(country);
  }

  getStatesByCountry(country: any) {
    const states = this.serviceLinesService.getStatesByCountry(country).subscribe(states => {
      this.state = states;
      if (country === 'CA') {
        this.state.unshift({ ['code']: '', ['name']: 'Elija una provincia' });
      }
      else {
        this.state.unshift({ ['code']: '', ['name']: 'Elija un estado' });
      }
      this.serviceLinesForm.controls.state.patchValue(this.state[0].code);
    });
    return states;
  }

}
