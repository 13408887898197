import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rot13Decrypt'
})
export class Rot13DecryptPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      return value.replace(/[a-zA-Z]/g, this.Decrypt);
    }

    return value;
  }

  //ROT13 Decrypt Single Character
  Decrypt(c: any) {
      return String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
  }
}
