import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { LogService } from '../service/log.service';
import { first } from 'rxjs/operators';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  logService:LogService;

  constructor(private log:LogService) {}

  handleError(error: string) {

    console.log(typeof error);

    console.log('Error: ' + error);
    this.log.postLog('Error', error.toString()).pipe(first()).toPromise();
    throw error;

  }
  
}
