import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlDecider } from '../../lib/url-decider';

@Component({
  selector: 'app-nav-main-sp',
  templateUrl: './nav-main-sp.component.html',
  styleUrls: ['./nav-main-sp.component.css']
})
export class NavMainSpComponent implements OnInit{

  searchQuery = '';
  linkEnglish: string;
  isNavbarOpen:boolean=false;
  navMenuToggle:any={
  isAboutUsMenu:false,
  isSellToUsMenu:false,
  isServicesMenu:false}
  scriptMap: any = null;
  screenWidth:number=0;

  constructor(private route: Router) {
    this.linkEnglish = UrlDecider.getEnglishURL();
  }
    ngOnInit(): void {
      this.screenWidth=window.innerWidth;
    }
  ngAfterViewInit() {
    this.loadScripts();
  }

  ngOnDestroy() {
    document.getElementsByTagName('head')[0].removeChild(this.scriptMap);
  }


  search() {
    this.route.navigate(['buscar', { s: this.searchQuery }]);
  }

  loadScripts() {
    this.scriptMap = document.createElement('script');
    this.scriptMap.src = '/assets/js/pushy.js';
    this.scriptMap.type = 'text/javascript';
    this.scriptMap.async = true;
    this.scriptMap.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(this.scriptMap);
  }
  onMenuToggle(name:string){
    if(this.screenWidth<993){
    for (const [key, value] of Object.entries(this.navMenuToggle)) {
      key === name ? (this.navMenuToggle[key] = !value) : (this.navMenuToggle[key] = false);
    }
  }
  }
  afterMenuToggle(name:string){
    this.navMenuToggle[name]=this.isNavbarOpen=false;
  }
}
