<app-nav-main></app-nav-main>
<section class="page-head">
  <div class="container h-100">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h1>CEO Office Hours
          <span>CEO Office Hours</span>
        </h1>
      </div>
    </div>
  </div>
  <img src="assets/images/ceo-office-hours.jpg" alt="CEO Office Hours">
</section>
<section class="container ptb-90">
  <div class="text-center mw-1250 mb-5">
    <div class="d-block d-md-inline"><img class="mw-100" src="assets/images/radius-recycling.jpg" alt="Radius Recycling" /></div>
    <div class="d-block d-md-inline mx-0 mx-md-5 my-2 my-md-0"><img class="mw-100" src="assets/images/pick-n-pull.jpg" alt="Pick-n-Pull" /></div>
    <div class="d-block d-md-inline"><img class="mw-100" src="assets/images/cascade-steel.jpg" alt="Cascade Steel" /></div>
  </div>
  <div style="max-width:900px; margin:0 auto;">
    <div *ngIf="isSubmitted">
      <div class="submit-success">
        <p>Your request was successfully submitted.</p>
      </div>
    </div>


    <div *ngIf="!isSubmitted">
      <form *ngIf="officeHours" [formGroup]="officeHours" (ngSubmit)="onSubmit()" id="officeHours">

        <p class="intro-paragraph">Please complete the following fields to submit interest in joining the upcoming CEO
          Office Hours session:</p>

        <div class="form-group">
          <label for="name">Employee Number&thinsp;*</label>
          <div class="field-row">
            <input type="text" class="form-control input-field"
              [class.is-invalid]="officeHours.get('employeeNumber').invalid && officeHours.get('employeeNumber').touched"
              id="employeeNumber" formControlName="employeeNumber" required />
            <div *ngIf="(employeeNumberValid.invalid && employeeNumberValid.touched)" class="text-danger">
              <div *ngIf="employeeNumberValid.errors?.required || employeeNumberValid.touched">
                Please provide a value for "Employee Number"
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="name">First Name</label>
          <div class="field-row">
            <input type="text" class="form-control input-field" id="firstName" formControlName="firstName" />
          </div>
        </div>

        <div class="form-group">
          <label for="name">Last Name</label>
          <div class="field-row">
            <input type="text" class="form-control input-field" id="lastName" formControlName="lastName" />
          </div>
        </div>

        <div class="form-group">
          <label for="phone">Phone&thinsp;*</label>
          <div class="field-row">
            <input type="" class="form-control input-field"
              [class.is-invalid]="officeHours.get('phone').invalid && officeHours.get('phone').touched" id="phone"
              formControlName="phone" required />
            <div *ngIf="phoneValid.invalid && phoneValid.touched" class="text-danger">
              <div *ngIf="phoneValid.errors?.required">
                Please provide a value for "Phone"
              </div>
              <div *ngIf="phoneValid.errors?.maxlength">
                Phone must be less than 250 characters.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email Address (company or personal address)&thinsp;*</label>
          <div class="field-row">
            <input type="email" class="form-control input-field"
              [class.is-invalid]="officeHours.get('email').invalid && officeHours.get('email').touched" id="email"
              formControlName="email" required />
            <div *ngIf="emailValid.invalid && emailValid.touched" class="text-danger">
              <div *ngIf="emailValid.errors?.required">
                Please provide a value for "Email Address"
              </div>
              <div *ngIf="emailValid.errors?.pattern">
                Please provide a valid email address.
              </div>
            </div>
          </div>
        </div>

        <p>* Indicates required field.</p>

        <button type="submit" id="submitButton" class="btn btn-secondary ccpa-button"
          [disabled]="!officeHours.valid">Submit</button>

      </form>
    </div>
  </div>
</section>
<app-footer></app-footer>