import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';

import { ConfigService } from '../shared/service/config.service';
import { DataPrivacyService } from '../shared/service/data-privacy.service';


@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.css']
})
export class DataPrivacyComponent {

  name: any;
  streetAddress: any;
  city: any;
  state: any;
  zip: any;
  email: any;
  phone: any;
  requestType: any;
  otherRequestType: any;
  customers: any;
  pnpCustomer: any;
  schnCustomer: any;
  csCustomer: any;

  recaptchaError: any;
  isRecaptchaValid = false;
  isSubmitted = false;
  public readonly siteKey = '6LevUxIaAAAAACH0xH0NqBtdOcaBxL3LgLnAxBCx';

  selectedValue = '';

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(private dataPrivacyService: DataPrivacyService, private configService: ConfigService) {
    this.getUSStates();
  }

  get nameValid() {
    return this.ccpaForm.get('name');
  }
  get streetValid() {
    return this.ccpaForm.get('streetAddress');
  }
  get cityValid() {
    return this.ccpaForm.get('city');
  }
  get zipValid() {
    return this.ccpaForm.get('zip');
  }
  get emailValid() {
    return this.ccpaForm.get('email');
  }
  get phoneValid() {
    return this.ccpaForm.get('phone');
  }
  get requestValid() {
    return this.ccpaForm.get('requestType');
  }
  get otherRequestValid() {
    return this.ccpaForm.get('otherRequestType')
  }
  get recaptcha() {
    return this.ccpaForm.get('recaptcha');
  }

  ccpaForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    streetAddress: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    city: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    state: new UntypedFormControl(''),
    zip: new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$')]),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
    phone: new UntypedFormControl('', [Validators.required, Validators.maxLength(15)]),
    requestType: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)]),
    otherRequestType: new UntypedFormControl('', [Validators.maxLength(250)]),
    recaptcha: new UntypedFormControl('', [Validators.required]),
    customers: new UntypedFormGroup({
      pnpCustomer: new UntypedFormControl(false),
      schnCustomer: new UntypedFormControl(false),
      csCustomer: new UntypedFormControl(false),
    }, requireCheckboxIsCheckedValidator),
  });


  getUSStates() {
    const states = this.dataPrivacyService.getStates().subscribe(states => {
      this.state = states;
      console.log(states);
      this.ccpaForm.controls.state.patchValue(this.state[6].code);
    });
    return states;
  }

  handleSuccess(response: any) {
    this.ccpaForm.markAllAsTouched();
    console.log(('handleSuccess'));
    this.isRecaptchaValid = true;
  }

  handleError() {
    this.isRecaptchaValid = false;
  }

  handleExpire() {
    this.isRecaptchaValid = false;
  }

  onSubmit() {
    this.ccpaForm.markAllAsTouched();

    if (!this.ccpaForm.valid) {
      return;
    }

    this.isSubmitted = true;

    this.dataPrivacyService.createFormData(this.ccpaForm.value).subscribe((data: any) => {
      this.ccpaForm = (data as any);
    });

  }
}

export function requireCheckboxIsCheckedValidator() {
  return function validate(formGroup: UntypedFormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (checked < 1) {
      return {
        requireCheckboxIsChecked: true,
      };
    }

    return null;
  }
}
