<app-nav-main></app-nav-main>
<section class="page-head">
  <div class="container h-100">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h1>Data Subject Privacy Request Form
          <span>Data Privacy</span>
        </h1>
      </div>
    </div>
  </div>
  <img src="assets/images/data-privacy.jpg" alt="Data Privacy">
</section>
<section class="container ptb-90">
  <div *ngIf="isSubmitted">
    <div class="submit-success">
      <p>Your request was successfully submitted.</p>
    </div>
  </div>
  <div *ngIf="!isSubmitted">
    <form *ngIf="ccpaForm" [formGroup]="ccpaForm" (ngSubmit)="onSubmit()" id="ccpaForm">

      <p class="intro-paragraph">
        Please complete the following fields and we will contact you to solicit additional information necessary to
        complete your request.
        <br />
        <span style="font-style: italic;">(This request form is intended for residents of California, Montana, Oregon, Texas, Utah, Virginia, and Canada)</span>
      </p>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="name">Name&thinsp;*</label>
            <div class="field-row">
              <input type="text" class="form-control input-field"
                [class.is-invalid]="ccpaForm.get('name').invalid && ccpaForm.get('name').touched" id="name"
                formControlName="name" required maxlength="250" />
              <div *ngIf="(nameValid.invalid && nameValid.touched)" class="text-danger">
                <div *ngIf="nameValid.errors?.required || nameValid.touched">
                  Please provide a value for "Name"
                </div>
                <div *ngIf="nameValid.errors.maxlength">
                  Name must be less than 250 characters.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="streetAddress">Street Address&thinsp;*</label>
            <div class="field-row">
              <input type="text" class="form-control input-field"
                [class.is-invalid]="ccpaForm.get('streetAddress').invalid && ccpaForm.get('streetAddress').touched"
                id="streetAddress" formControlName="streetAddress" required maxlength="250" />
              <div *ngIf="streetValid.invalid && streetValid.touched" class="text-danger">
                <div *ngIf="streetValid.errors?.required">
                  Please provide a value for "Street Address"
                </div>
                <div *ngIf="streetValid.errors?.maxlength">
                  Street Address must be less than 250 characters.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="city">City&thinsp;*</label>
            <div class="field-row">
              <input type="text" class="form-control input-field"
                [class.is-invalid]="ccpaForm.get('city').invalid && ccpaForm.get('city').touched" id="city"
                formControlName="city" required />
              <div *ngIf="cityValid.invalid && cityValid.touched" class="text-danger">
                <div *ngIf="cityValid.errors?.required">
                  Please provide a value for "City"
                </div>
                <div *ngIf="cityValid.errors?.maxlength">
                  City must be less than 250 characters.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="state">State, Province, or Territory&thinsp;*</label>
            <select class="form-control input-field" id="state" formControlName="state">
              <option *ngFor="let s of state; let i = index" [value]="state[i].code">{{state[i].name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="zip">Postcode or ZIP Code&thinsp;*</label>
            <div class="field-row">
              <input type="text" class="form-control input-field"
                [class.is-invalid]="ccpaForm.get('zip').invalid && ccpaForm.get('zip').touched" id="zip"
                formControlName="zip" required />
              <div *ngIf="zipValid.invalid && zipValid.touched" class="text-danger">
                <div *ngIf="zipValid.errors?.required">
                  Please provide a value for "ZIP Code"
                </div>
                <div *ngIf="zipValid.errors?.pattern">
                  Postcode or ZIP Code must be in a valid format.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="email">Email Address&thinsp;*</label>
            <div class="field-row">
              <input type="email" class="form-control input-field"
                [class.is-invalid]="ccpaForm.get('email').invalid && ccpaForm.get('email').touched" id="email"
                formControlName="email" required />
              <div *ngIf="emailValid.invalid && emailValid.touched" class="text-danger">
                <div *ngIf="emailValid.errors?.required">
                  Please provide a value for "Email Address"
                </div>
                <div *ngIf="emailValid.errors?.pattern">
                  Please provide a valid email address.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="phone">Phone&thinsp;*</label>
            <div class="field-row">
              <input type="" class="form-control input-field"
                [class.is-invalid]="ccpaForm.get('phone').invalid && ccpaForm.get('phone').touched" id="phone"
                formControlName="phone" required />
              <div *ngIf="phoneValid.invalid && phoneValid.touched" class="text-danger">
                <div *ngIf="phoneValid.errors?.required">
                  Please provide a value for "Phone"
                </div>
                <div *ngIf="phoneValid.errors?.maxlength">
                  Phone must be less than 250 characters.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="requestType">Type of Request&thinsp;*</label>
            <select class="form-control input-field" id="requestType" formControlName="requestType" required
                    [(ngModel)]="selectedValue">
              <option value="Right to Know">Right to Know</option>
              <option value="Right to Delete">Right to Delete</option>
              <option value="Right to Correct">Right to Correct</option>
              <option value="Right to Appeal">Right to Appeal</option>
              <option value="Right to Access">Right to Access</option>
              <option value="Right to Withdraw Consent">Right to Withdraw Consent</option>
              <option value="Right to Opt-out">Right to Opt-out</option>
              <option value="Other">Other</option>
            </select>
            <div *ngIf="requestValid.invalid && requestValid.touched" class="text-danger">
              <div *ngIf="requestValid.errors?.required">
                Please specify your type of request
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="selectedValue === 'Other'">
        <label for="otherRequestType">Other</label>
        <div class="field-row">
          <input type="text" class="form-control input-field" id="otherRequestType"
            formControlName="otherRequestType" />
          <div *ngIf="otherRequestValid.invalid && otherRequestValid.touched" class="text-danger">
            <div *ngIf="otherRequestValid.errors?.maxlength">
              Other request type must be less than 250 characters.
            </div>
          </div>
        </div>
      </div>
      <p class="form_hints" style="margin-bottom: 5px;">Please check all that apply.* <br />At least one checkbox must
        be checked.</p>
      <div class="form-group" formGroupName="customers">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="pnpCustomer" formControlName="pnpCustomer">
          <label class="form-check-label" for="pnpCustomer">
            I am a Pick-n-Pull customer, supplier, or business partner
          </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="schnCustomer" formControlName="schnCustomer">
          <label class="form-check-label" for="schnCustomer">
            I am a Radius Recycling customer, supplier, or business partner
          </label>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="csCustomer" formControlName="csCustomer">
          <label class="form-check-label" for="csCustomer">
            I am a Cascade Steel customer, supplier, or business partner
          </label>
        </div>
      </div>

      <div class="invalid-feedback"
        *ngIf="ccpaForm.controls['customers'].errors && ccpaForm.controls['customers'].errors.requireCheckboxIsChecked">
        At least one checkbox must be checked.
      </div>

      <div class="recaptcha-div">
        <div id="recaptchaElem">
          <ngx-recaptcha2 #captchaElem theme="white" hl="en" siteKey="{{siteKey}}" formControlName="recaptcha"
            id="recaptcha" (success)="handleSuccess($event)"></ngx-recaptcha2>
        </div>
      </div>

      <p>* Indicates required field.</p>

      <button type="submit" id="submitButton" class="btn btn-secondary" [disabled]="!ccpaForm.valid">Submit</button>

    </form>
  </div>

</section>
<app-footer></app-footer>
