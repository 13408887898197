<app-nav-main></app-nav-main>
<section class="page-head">
  <div class="container h-100">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h1>Site Map
          <span>Site Map</span>
        </h1>
      </div>
    </div>
  </div>
  <img src="assets/images/site-map/sitemap.jpg" alt="Site Map">
</section>
<section class="ptb-90 site-map">
  <div class="container">
    <div class="row">
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>About Us</h2>
          <img src="assets/images/company/our-company.jpg" alt="Our Company">
        </div>
        <ul>
          <li><a [routerLink]="'/company'"><i class="fa-solid fa-arrow-right"></i> Our Company</a></li>
          <li><a [routerLink]="'/company/about-radius-recycling/management'"><i class="fa-solid fa-arrow-right"></i> Leadership</a></li>
          <li><a [routerLink]="'/company/about-radius-recycling/history'"><i class="fa-solid fa-arrow-right"></i> History</a></li>
          <li><a [routerLink]="'/company/about-radius-recycling/culture'"><i class="fa-solid fa-arrow-right"></i> Culture</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-8 mb-4">
        <div class="shadow h-100">
          <div class="head">
            <h2>Investors</h2>
            <img src="assets/images/investors/investors.jpg" alt="Investors">
          </div>
         <div class="row">
          <div class="col-md-6">
            <ul class="pb0">
            <li><a [routerLink]="'/company/investors/stock-information'"><i class="fa-solid fa-arrow-right"></i> Stock Information</a></li>
            <li><a [routerLink]="'/company/investors/stock-info/dividend-history'"><i class="fa-solid fa-arrow-right"></i> Dividend History</a></li>
            <li><a [routerLink]="'/company/investors/news-releases'"><i class="fa-solid fa-arrow-right"></i> News</a></li>
            <li><a [routerLink]="'/company/investors'" fragment="investorMaterials"><i class="fa-solid fa-arrow-right"></i> Investor Materials</a></li>
          </ul>
        </div>
        <div class="col-md-6">
          <ul class="pt0">
            <li><a [routerLink]="'/company/investors/corporate-governance'"><i class="fa-solid fa-arrow-right"></i> Corporate Governance</a></li>  
          <li><a [routerLink]="'/company/investors/sec-filings'"><i class="fa-solid fa-arrow-right"></i> SEC Filngs</a></li>
          <li><a [routerLink]="'/company/investors/sec-filings/director'"><i class="fa-solid fa-arrow-right"></i> Director & Officer Filings</a></li>
          <li><a [routerLink]="'/company/investors/email-alerts'"><i class="fa-solid fa-arrow-right"></i> E-mail Alerts</a></li>
        </ul>
      </div>
         </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Sustainability</h2>
          <img src="assets/images/sustainability/sustainability.jpg" alt="Sustainability">
        </div>
        <ul>
          <li><a [routerLink]="'/company/sustainability'"><i class="fa-solid fa-arrow-right"></i> Sustainability</a></li>
          <li><a [routerLink]="'/company/sustainability'" fragment="Reports"><i class="fa-solid fa-arrow-right"></i> ESG Data Hub</a></li>
          <li><a [routerLink]="'/company/sustainability'" fragment="Reports"><i class="fa-solid fa-arrow-right"></i> Past Reports</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Careers</h2>
          <img src="assets/images/careers/careers.jpg" alt="Careers">
        </div>
        <ul>
          <li><a [routerLink]="'/company/careers'"><i class="fa-solid fa-arrow-right"></i> Overview</a></li>
          <li><a href="https://recruiting2.ultipro.com/SCH1011SSTL/JobBoard/02ac23c5-914c-4ab4-9851-dc1bc7bd9c94/?q=&o=postedDateDesc&w=&wc=&we=&wpst=)" target="_blank"><i class="fa-solid fa-arrow-right"></i> Join Our Team</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Sell to Us</h2>
          <img src="assets/images/sell-to-us/sell-to-us.jpg" alt="Sell to Us">
        </div>
        <ul>
          <li><a [routerLink]="'/sell-to-us'"><i class="fa-solid fa-arrow-right"></i> Sell to Us</a></li>
          <li><a [routerLink]="'/sell-to-us/what-we-buy'"><i class="fa-solid fa-arrow-right"></i> Accepted Materials</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Buy from Us</h2>
          <img src="assets/images/buy-from-us/buy-from-us.jpg" alt="Buy from Us">
        </div>
        <ul>
          <li><a [routerLink]="'/buy-from-us'"><i class="fa-solid fa-arrow-right"></i> Our Products</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Services</h2>
          <img src="assets/images/services/services.jpg" alt="Services">
        </div>
        <ul>
          <li><a [routerLink]="'/service-lines/vehicle-recycling-services'"><i class="fa-solid fa-arrow-right"></i>3PR<sup>TM</sup> Third Party Recycling</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Contact Us</h2>
          <img src="assets/images/company/contact-us.jpg" alt="Contact Us">
        </div>
        <ul>
          <li><a [routerLink]="'/company/contacts'"><i class="fa-solid fa-arrow-right"></i> Contact Us</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mt-md-0 mt-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Locations</h2>
          <img src="assets/images/locations.jpg" alt="Locations">
        </div>
        <ul>
          <li><a [routerLink]="'/locations'"><i class="fa-solid fa-arrow-right"></i> Our Locations</a></li>
          <li><a [routerLink]="'/locations/search'"><i class="fa-solid fa-arrow-right"></i> Location Search</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mt-md-0 mt-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Ethics & Code Of Conduct</h2>
          <img src="assets/images/ethics/ethics-code-of-conduct.jpg" alt="Ethics & Code Of Conduct">
        </div>
        <ul>
          <li><a [routerLink]="'/company/about-radius-recycling/ethics'"><i class="fa-solid fa-arrow-right"></i> Ethics</a></li>
          <li><a [routerLink]="'/company/about-radius-recycling/ethics/political-contributions'"><i class="fa-solid fa-arrow-right"></i> Political Contributions</a></li>
        </ul>
      </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
