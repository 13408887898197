<app-nav-main-sp></app-nav-main-sp>
<section class="page-head">
  <div class="container h-100">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h1>Avisos Legales Del Sitio Web
          <span>Avisos Legales Del Sitio Web</span>
        </h1>
      </div>
    </div>
  </div>
  <img src="assets/images/legal-notices.jpg" alt="Avisos Legales Del Sitio Web">
</section>
<section class="container ptb-90">
  <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
    <p>
      Residentes de California: Haga clic aquí para enlazar directamente con nuestro
      <a [routerLink]="['/avisos-legales']" fragment="privacidad">Aviso en el momento de la recopilación</a>.
    </p>
    <a href="https://www.radiusrecycling.com/data-privacy" class="btn btn-secondary">
      To make a Data Subject Privacy
      <br />Request, click here
    </a>
  </div>

  <div class="legal-notices">
    <h2 class="title-secondary">Condiciones de uso</h2>

    <p>Gracias por visitar el sitio web de Schnitzer Steel Industries, Inc. <i>dba</i> Radius Recycling ("Radius"). (Las referencias a Radius incluyen sus subsidiarias de propiedad mayoritaria o total, y las referencias al sitio web de Radius incluyen todos los sitios operados por Radius o en su nombre).</p>
    <p>
      Lea atentamente estos términos antes de utilizar este sitio web. El uso de este sitio web indica que acepta estos
      términos. Radius puede cambiar estos términos en cualquier momento y su uso está sujeto a los cambios. Si no
      acepta estos términos, no utilice este sitio web.
    </p>
    <p>
      La información proporcionada en este sitio web se ha proporcionado para su uso, pero sigue siendo propiedad de
      Radius. Se prohíbe el uso o reproducción del contenido o los materiales para cualquier fin que no cuente con
      autorización expresa. Este sitio web, sus diseños y todo el material que contiene son propiedad intelectual de
      bajo las leyes de derechos de autor aplicables de EE. UU.
      &#169; Radius Recycling
    </p>
    <p>
      Si bien hemos realizado esfuerzos razonables para garantizar la precisión de este sitio web, no garantizamos que
      el sitio web esté libre de errores, ni garantizamos que la información sea precisa, correcta, fiable o esté
      actualizada.
    </p>
    <p>
      ESTE SITIO WEB Y SU CONTENIDO SE PROPORCIONAN "TAL CUAL". Radius RENUNCIA A TODAS LAS GARANTÍAS, EXPRESAS O
      IMPLÍCITAS, INCLUIDAS, ENTRE OTRAS, LAS GARANTÍAS DE COMERCIABILIDAD, IDONEIDAD PARA UN USO PARTICULAR O AUSENCIA
      DE INFRACCIÓN.
    </p>
    <p>
      EN NINGÚN CASO Radius, SUS FILIALES, EMPLEADOS, DIRECTORES, ACCIONISTAS, FUNCIONARIOS, CONTRATISTAS O AGENTES
      SERÁN RESPONSABLES ANTE USTED POR NINGÚN DAÑO INDIRECTO, INCIDENTAL, ESPECIAL, CONSECUENTE, EJEMPLAR O PUNITIVO
      (INCLUIDA LA PÉRDIDA DE BENEFICIOS) QUE RESULTE DE SU USO O INCAPACIDAD DE USAR ESTE SITIO WEB, INCLUSO SI SE HA
      ADVERTIDO A Radius DE LA POSIBILIDAD DE DICHOS DAÑOS. LA LIMITACIÓN ANTERIOR SE APLICARÁ SIN TENER EN CUENTA LA
      FORMA DE ACCIÓN, YA SEA CONTRACTUAL, EXTRACONTRACTUAL, DE RESPONSABILIDAD ESTRICTA O DE OTRO TIPO.
    </p>
    <p>
      Usted acepta defender, indemnizar y eximir de responsabilidad a Radius y a cualquiera de sus filiales,
      empleados, directores, accionistas, funcionarios, contratistas o agentes de y contra cualquier reclamación,
      acción, responsabilidad, coste o daño alegado por cualquier tercero que surja de o esté relacionado con su uso de
      este sitio web.
    </p>

    <h2 class="title-secondary" id="privacidad" #privacidad>
      Política de privacidad y aviso en el momento de la
      recopilación
    </h2>
    <p style="font-weight: bold;" class="tc-secondary">Última actualización: 29 de diciembre de 2022</p>

    <p style="font-weight: bold;" class="tc-secondary">I. Introducción</p>

    <p>
      Esta Política de privacidad ("<span style="font-weight: bold;">Política</span>") describe cómo Schnitzer Steel Industries Inc. <i>dba</i> Radius Recycling, incluidas sus subsidiarias de propiedad mayoritaria
      y total, incluidas, entre otras, Pick-n-Pull y Cascade Steel Rolling Mills, Inc. ("<span style="font-weight: bold;">Radius</span>", "<span style="font-weight: bold;">nosotros</span>" o "<span style="font-weight: bold;">nos</span>") maneja la Información
      Personal que recopila en línea, en ubicaciones físicas, por teléfono o de otro modo. A menos que se indique lo
      contrario, la información
      de esta Política que describe cómo y por qué recopilamos, utilizamos y divulgamos información en los últimos 12
      meses también describe la
      información que recopilaremos y cómo la utilizaremos. A los efectos de esta Política, la Información Personal
      ("<span style="font-weight: bold;">IP</span>") se refiere a la
      información que identifica, se relaciona, describe, puede asociarse razonablemente o podría vincularse
      razonablemente, directa o indirectamente, con usted o su hogar.
    </p>

    <p>
      Esta Política se aplica a las interacciones que pueda tener con Radius en su capacidad individual o en su
      capacidad como empleado, propietario, director, funcionario o contratista independiente de otra empresa, sociedad,
      propiedad exclusiva, organización sin ánimo de lucro o agencia gubernamental (colectivamente, "<span style="font-weight: bold;">Entidad</span>").
    </p>

    <p>
      Podemos cambiar los términos de la Política de vez en cuando. Cuando realicemos cambios, publicaremos la Política
      modificada en este sitio web, <a routerLink="/avisos-legales">dataprivacy.rdus.com</a>, que sirve como
      Política de privacidad y Aviso en el momento de la recopilación para todos los sitios web de Radius ("<span style="font-weight: bold;">Sitios</span>"). Si los cambios son importantes, publicaremos un aviso en este sitio
      web para avisarle del cambio. Cualquier cambio en la Política entrará en vigor inmediatamente después de nuestra
      publicación de la Política, así que revise la Política actual antes de proporcionarnos la Información Personal.
    </p>

    <p style="font-weight:bold;" class="tc-secondary">
      II. Qué Información Personal recopila Radius y por qué la
      recopilamos
    </p>

    <p>
      En los últimos 12 meses, hemos recopilado estas categorías de Información Personal (el hecho de que hayamos
      recopilado una categoría específica de información sobre usted depende de su interacción con nosotros):
    </p>

    <table>
      <tr>
        <th width="40%">
          Categoría de Información Personal
        </th>
        <th>
          Fines de la recopilación, el uso y la divulgación
        </th>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">Identificadores</span>, incluidos su nombre real, alias, dirección postal,
          identificador personal único, identificador en línea, dirección IP, dirección de correo electrónico, nombre de
          cuenta u otros identificadores similares
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para comunicarnos con usted, incluso en respuesta a sus consultas y para satisfacer sus solicitudes
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para proporcionarle información sobre nuestros productos y servicios, y para proporcionarle productos o
              servicios que nos haya solicitado, para usted o en nombre de una Entidad a la que esté afiliado
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar nuestras operaciones y negocios, por ejemplo, con fines fiscales, de cumplimiento y
              contables
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para realizar negocios con una Entidad a la que está afiliado
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar la seguridad e integridad de nuestras instalaciones, sistemas y datos
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">
            Información Personal descrita en el Código Civil de California § 1798.80(e)
            (Información de "Registros del Cliente")
          </span> que incluye su nombre, firma, número de la seguridad social,
          características físicas o descripción, dirección, número de teléfono, número de pasaporte, número de permiso
          de conducir o número de tarjeta de identificación estatal, número de póliza de seguro, educación, empleo,
          antecedentes laborales, número de cuenta bancaria, número de tarjeta de crédito, número de tarjeta de débito u
          otra información financiera, información médica o información de seguro médico
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para comunicarnos con usted, incluso en respuesta a sus consultas y para satisfacer sus solicitudes
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para proporcionarle información sobre nuestros productos y servicios, y para proporcionarle productos o
              servicios que nos haya solicitado, para usted o en nombre de una Entidad a la que esté afiliado
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar nuestras operaciones y negocios, por ejemplo, con fines fiscales, de cumplimiento y
              contables
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para realizar negocios con una Entidad a la que está afiliado
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar la seguridad e integridad de nuestras instalaciones, sistemas y datos
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">Información comercial</span>, incluidos registros de bienes personales,
          productos o servicios adquiridos, obtenidos o considerados, u otros historiales o tendencias de compra o
          consumo
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para comunicarnos con usted, incluso en respuesta a sus consultas y para satisfacer sus solicitudes
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para proporcionarle información sobre nuestros productos y servicios, y para proporcionarle productos o
              servicios que nos haya solicitado, para usted o en nombre de una Entidad
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar nuestras operaciones y negocios, por ejemplo, con fines fiscales, de cumplimiento y
              contables
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para realizar negocios con una Entidad a la que está afiliado
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar la seguridad e integridad de nuestras instalaciones, sistemas y datos
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">Información de actividad de Internet u otra red electrónica</span>, incluidos
          el historial de navegación, el historial de búsqueda e información sobre sus interacciones con nuestros
          Sitios, aplicaciones móviles o anuncios, incluida la información de hardware y navegador de su computadora u
          otro dispositivo en línea
          <br /><br />
          A continuación, consulte la Información que recopilamos automáticamente en línea para obtener información
          adicional
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para comunicarnos con usted, incluso en respuesta a sus consultas y para satisfacer sus solicitudes
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para proporcionarle información sobre nuestros productos y servicios, y para proporcionarle productos o
              servicios que nos haya solicitado, para usted o en nombre de una Entidad
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar nuestras operaciones y negocios, por ejemplo, con fines fiscales, de cumplimiento y
              contables
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para realizar negocios con una Entidad a la que está afiliado
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar la seguridad e integridad de nuestras instalaciones, sistemas y datos
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">Datos de geolocalización</span>, incluidos los datos de ubicación inferidos
          de la dirección IP de su dispositivo cuando visita nuestros Sitios
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para comunicarnos con usted, incluso en respuesta a sus consultas y para satisfacer sus solicitudes
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para proporcionarle información sobre nuestros productos y servicios, y para proporcionarle productos o
              servicios que nos haya solicitado, para usted o en nombre de una Entidad
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para realizar negocios con una Entidad a la que está afiliado
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar la seguridad e integridad de nuestras instalaciones, sistemas y datos
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">Información biométrica</span>, como huellas dactilares
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar la seguridad e integridad de nuestras instalaciones, sistemas y datos
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para cumplir con las leyes y normativas aplicables
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">
            Información sensorial de audio, electrónica, visual, térmica o
            similar
          </span>, incluidas la videovigilancia y las grabaciones del centro de llamadas
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar la seguridad e integridad de nuestras instalaciones, sistemas y datos
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para cumplir con las leyes y normativas aplicables
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar las tareas laborales, evaluar el rendimiento y operar nuestro negocio
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">Información profesional o relacionada con el empleo</span>, incluidos los
          antecedentes laborales actuales o del pasado o información sobre la Entidad a la que está afiliado y su
          función en esa Entidad si interactúa con nosotros como representante de una Entidad
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para comunicarnos con usted, incluso en respuesta a sus consultas y para satisfacer sus solicitudes
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para proporcionarle información sobre nuestros productos y servicios, y para proporcionarle productos o
              servicios que nos haya solicitado, para usted o en nombre de una Entidad
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para evaluar la solvencia o realizar comprobaciones de cumplimiento en relación con una Entidad a la que
              está afiliado
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para proporcionar otros productos o servicios que nos haya solicitado en nombre de una Entidad
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para realizar negocios con una Entidad a la que está afiliado
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <span style="font-weight: bold;">Inferencias extraídas de otra Información Personal</span> y utilizadas para
          crear un perfil que refleje sus preferencias, características, tendencias psicológicas, predisposición,
          comportamiento, actitudes, inteligencia, habilidades y aptitudes.
        </td>
        <td>
          <ul>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para proporcionarle información sobre nuestros productos y servicios, y para proporcionarle productos o
              servicios que nos haya solicitado, para usted o en nombre de una Entidad
            </li>
            <li>
              <i class="fa-solid fa-arrow-right"></i>
              Para gestionar la seguridad e integridad de nuestras instalaciones, sistemas y datos
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <p>&nbsp;</p>
    <p>
      También podemos recopilar, utilizar o divulgar la Información Personal descrita anteriormente para otros fines
      autorizados por las leyes aplicables, que incluyen:
    </p>

    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        para llevar a cabo análisis de datos internos y desarrollo de productos;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        para responder a las solicitudes de cumplimiento de la ley o de procesos legales;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        para detectar, investigar, impedir o abordar de otro modo el fraude u otros problemas de seguridad e integridad,
        o para mantener la seguridad e integridad de nuestros productos y servicios, incluidas la investigación y la
        respuesta a quejas sobre conducta indebida (consulte la sección Privacidad de los informes de conducta indebida
        [Misconduct Reporting Privacy], a continuación, para obtener información adicional sobre cómo recopilamos y
        utilizamos la información relacionada con las quejas sobre conducta indebida);
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        para ejercer o defendernos contra reclamaciones legales, incluida la investigación de dichas reclamaciones;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        para cumplir con las leyes y normativas aplicables; o
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        para formar parte de una transacción o procedimiento corporativo, como una fusión, financiación, adquisición,
        quiebra, disolución o transferencia, desinversión o venta de la totalidad o una parte de nuestro negocio o
        activos.
      </li>
    </ul>

    <p style="font-weight: bold;" class="tc-secondary">III. Fuentes de Información Personal</p>

    <p>
      Radius recopila la Información Personal sobre usted cuando usted nos la proporciona activamente, por ejemplo,
      completando un formulario en línea, registrando un producto o servicio que proporcionamos, respondiendo a una
      solicitud de información, inscribiéndose para recibir comunicaciones nuestras, enviándonos un correo electrónico o
      una carta, completando una hoja o formulario de registro en una de nuestras instalaciones (incluido
      electrónicamente) o proporcionando información para vender materiales para reciclaje, un vehículo al final de su
      vida útil u otros productos o servicios. También podemos recopilar Información Personal sobre usted de otras
      fuentes, como nuestros socios comerciales y filiales; Internet o fuentes disponibles públicamente, incluidos
      sitios web de redes sociales; la prensa u otros medios impresos; partes en transacciones corporativas; proveedores
      de servicios; y de otras organizaciones o personas según lo permita la legislación aplicable.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">IV. Información que recopilamos automáticamente en línea</p>

    <p>Cuando visita cualquiera de nuestros Sitios, podemos recopilar la siguiente información automáticamente:</p>

    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        información técnica, incluida la dirección de protocolo de Internet (internet protocol, IP) utilizada para
        conectar su computadora a Internet, su información de inicio de sesión para nuestros Sitios, tipo y versión de
        navegador, configuración de zona horaria, configuración del idioma del navegador, tipos y versiones de
        complementos del navegador, sistema operativo y plataforma; e
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        información sobre su visita, incluidos los Localizadores Uniformes de Recursos (Uniform Resource Locators, URL)
        completos, la secuencia de clics hacia, a través de y desde el Sitio (incluidas la fecha y la hora), los
        productos que ha visto o buscado, los tiempos de respuesta de la página, los errores de descarga, la duración de
        las visitas a ciertas páginas, la información de interacción de la página (como desplazamiento, clics y
        desplazamientos del ratón) y los métodos utilizados para navegar fuera de la página.
      </li>
    </ul>

    <p>
      Podemos obtener esta información a través de registros del servidor, cookies (pequeños archivos de datos que su
      navegador web almacena en su disco duro), balizas web (pequeñas imágenes electrónicas utilizadas para contar las
      vistas de páginas web) u otros mecanismos de uso común.
    </p>

    <p>
      Nuestros Sitios utilizan cookies para distinguirlo como usuario del Sitio de otros usuarios de nuestros Sitios.
      Esto nos ayuda a proporcionarle una buena experiencia cuando navega por nuestros Sitios y nos permite mejorar los
      Sitios. Cada vez que regresa a un Sitio que visitó anteriormente, el navegador recupera la cookie y la envía al
      servidor del Sitio. También utilizamos cookies que recuerdan las páginas que ha visitado y los enlaces que ha
      seguido, que utilizamos y podemos compartir con terceros para ayudar a que nuestros Sitios sean más relevantes
      para sus intereses. Y, más ampliamente, utilizamos cookies para ver cuántos visitantes hay en nuestros Sitios y
      cómo se mueven los visitantes por los Sitios. Estas cookies nos ayudan a mejorar la forma en que funcionan los
      Sitios, por ejemplo, asegurándose de que los usuarios encuentren lo que buscan fácilmente.
    </p>

    <p>
      Puede bloquear las cookies activando la configuración de su navegador que le permite rechazar la configuración de
      todas o algunas cookies. Sin embargo, si utiliza la configuración de su navegador para bloquear todas las cookies,
      es posible que no pueda acceder a la totalidad o parte de nuestros Sitios. Para obtener más información sobre las
      cookies, visite <a href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org/</a>.
    </p>

    <p>
      Al utilizar cualquiera de los Sitios, se considera que acepta inequívocamente el uso de todas las cookies que no
      deshabilite.
    </p>

    <p>
      También utilizamos herramientas como Google Analytics (que a su vez utiliza cookies) para obtener más información
      sobre las preferencias y actividades de los visitantes del Sitio cuando utilizan los Sitios. Google Analytics
      utiliza cookies para recopilar información sobre los visitantes del Sitio para proporcionar servicios de análisis
      basados en las visitas de un usuario a sitios a través de Internet y en otras aplicaciones móviles. Para obtener
      más información sobre la recopilación y el uso de datos a través de Google Analytics, consulte <a href="https://www.google.com/policies/privacy/partners/" target="_blank">www.google.com/policies/privacy/partners/</a>. Google
      ofrece la posibilidad de excluirse del seguimiento a través de las cookies de Google Analytics; para obtener más
      información al respecto, visite: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">tools.google.com/dlpage/gaoptout</a>.
    </p>

    <p>Nuestros Sitios no responden a la solicitud de "No Seguir" (Do-Not-Track) de un usuario.</p>

    <p style="font-weight: bold;" class="tc-secondary">V. A quién divulgamos Información Personal</p>

    <p>
      Hemos divulgado, o podemos divulgar, cada categoría de Información Personal descrita anteriormente a estas
      categorías de destinatarios:
    </p>
    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        nuestros proveedores de servicios, a quienes contratamos para ayudarnos con el soporte tecnológico, el soporte
        operativo y otras formas de asistencia, y a quienes vinculamos por contrato para proteger la confidencialidad y
        la seguridad de la Información Personal que compartimos con ellos;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        nuestras entidades afiliadas dentro de la familia corporativa Radius, para fines legalmente permitidos;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        la parte adquirente o cesionario propuesto o real en caso de una reorganización, fusión, venta, empresa
        conjunta, cesión, transferencia u otra disposición propuesta o real de la totalidad o parte de nuestra empresa o
        sus activos;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        agencias de aplicación de la ley, tribunales y agencias gubernamentales cuando la divulgación sea requerida o
        permitida por la ley, incluido para cumplir con un proceso legal válido; o
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        otras partes que consideremos apropiadas: (i) cuando lo exija la legislación vigente, incluidas las leyes fuera
        de su país de residencia; (ii) para cumplir con un proceso legal; (iii) para responder a solicitudes de
        autoridades públicas y gubernamentales; (iv) para hacer cumplir los términos y condiciones de uso de los Sitios,
        incluida esta Política; (v) para proteger y defender nuestros derechos y propiedades; (vi) para proteger los
        intereses de Radius u otros; y (vii) para permitirnos buscar recursos disponibles o limitar los daños que
        podamos sufrir.
      </li>
    </ul>

    <p>
      Permitimos que ciertas empresas coloquen tecnologías de seguimiento en nuestros Sitios, lo que permite a esas
      empresas recibir información sobre su actividad en nuestros Sitios asociada con su navegador o dispositivo. Esta
      información puede incluir identificadores compartidos, información comercial, información de ubicación e
      información de actividad de Internet u otra red electrónica. Las empresas pueden utilizar esos datos para
      mostrarle más anuncios en nuestros sitios o en los sitios de otros. Según la actividad implicada, esta actividad
      puede considerarse una venta de Información Personal o un intercambio de Información Personal para publicidad
      dirigida en virtud de ciertas leyes estatales. Excepto para este tipo de venta o intercambio, no vendemos
      Información Personal de otro modo y no compartimos Información Personal con entidades no afiliadas para que las
      utilicen para sus propios fines de marketing.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">
      VI. Divulgaciones y derechos adicionales para residentes de
      California
    </p>

    <p>
      Esta sección proporciona divulgaciones adicionales y describe los derechos adicionales aplicables a los
      residentes de California en virtud de la Ley de Protección de Datos de los Consumidores de California (California
      Consumer Privacy Act, "<span style="font-weight: bold;">CCPA</span>"), con sus enmiendas. Los términos en
      mayúsculas de esta sección tienen el mismo significado que los términos correspondientes definidos en la CCPA.
    </p>

    <p>Tiene derecho a no ser discriminado por ejercer sus derechos en virtud de la CCPA.</p>

    <p class="tc-secondary">
      A. <span style="text-decoration: underline;">
        Derecho a excluirse de la Venta y del Intercambio de Información
        Personal
      </span>
    </p>
    <p>
      You may opt out of the Sale or Sharing of yoPuede excluirse de la Venta o del Intercambio de su Información
      Personal modificando su configuración de cookies a través del enlace "No vender ni intercambiar mi Información
      Personal" (Do Not Sell or Share My Personal Information) en el pie de página de nuestro sitio web. También hemos
      habilitado el Control de privacidad global (Global Privacy Control), que está diseñado para reconocer las señales
      de preferencia de exclusión. Para procesar su solicitud a través de una señal de preferencia de exclusión
      voluntaria, debe utilizar un navegador o una extensión que admita la señal de preferencia. Si decide utilizar una
      señal de preferencia de exclusión voluntaria, deberá activarla para cada navegador y cada dispositivo que utilice.
    </p>
    <p class="tc-secondary">
      B. <span style="text-decoration: underline;">Información Personal sensible</span>
    </p>
    <p>
      No recopilamos Información Personal sensible con el fin de inferir características sobre usted. No utilizamos ni
      divulgamos Información Personal sensible para fines a los que se aplique el derecho a limitar el uso y la
      divulgación en virtud de la CCPA.
    </p>

    <p class="tc-secondary">C. <span style="text-decoration: underline;">Derecho a saber, eliminar y corregir</span></p>
    <p>
      La CCPA otorga a los Consumidores de California ciertos derechos. Cuando sea necesario, le pediremos que
      verifique su identidad antes de atender sus solicitudes (esto incluye las solicitudes realizadas por agentes
      autorizados).
    </p>

    <p>
      <span style="font-weight: bold;">Derecho a saber.</span> Si es residente de California, tiene derecho a solicitar
      que le proporcionemos la siguiente información:
    </p>
    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        las categorías y partes específicas de Información Personal que hemos recopilado sobre usted en los últimos 12
        meses;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        las categorías de fuentes de las que recopilamos Información Personal;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        los Fines comerciales o empresariales para recopilar, utilizar, compartir o vender Información Personal;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        las categorías de Información Personal que hemos divulgado sobre usted para un Fin comercial y las categorías de
        destinatarios a los que se divulgó; y
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        las categorías de Información Personal que hemos Vendido o Intercambiado sobre usted y las categorías de
        Terceros a los que se ha Vendido o Intercambiado cada categoría de Información Personal.
      </li>
    </ul>

    <p>
      <span style="font-weight: bold;">Derecho a eliminar. </span> Tiene derecho a solicitar que eliminemos Información
      Personal en determinadas circunstancias, con sujeción a varias excepciones.
    </p>

    <p>
      <span style="font-weight: bold;">Derecho a corregir.</span> Tiene derecho a solicitar que corrijamos la
      Información Personal que conservamos sobre usted si cree que es inexacta. Podemos solicitarle documentación en
      relación con su solicitud. Tras recibir una solicitud verificable para corregir la Información Personal inexacta,
      haremos todo lo comercialmente razonable para corregir la Información Personal. En determinadas aplicaciones,
      también puede corregir cierta Información Personal que mantenemos sobre usted iniciando sesión en su cuenta.
    </p>

    <p class="tc-secondary">D. <span style="text-decoration: underline;">Cómo enviar una solicitud</span></p>

    <p>
      Para ejercer los derechos descritos anteriormente, envíenos una solicitud por cualquiera de los siguientes
      medios:
    </p>

    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        Envíe la solicitud a través de nuestro formulario web interactivo, en <a [routerLink]="'/data-privacy'">https://www.radiusrecycling.com/data-privacy</a>; o
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        Llámanos a nuestro número gratuito de privacidad de datos: (833) 824-1685.
      </li>
    </ul>

    <p style="font-weight: bold;" class="tc-secondary">
      VII. Divulgaciones y derechos adicionales para residentes de
      Virginia y Utah
    </p>

    <p>
      Esta sección se aplica a los consumidores de Virginia y Utah, según se define en la Ley de Protección de Datos del
      Consumidor de Virginia (Virginia Consumer Data Protection Act, <span style="font-weight: bold;">VCDPA</span>),
      o Ley de Privacidad del Consumidor de Utah ("UCPA") que
      acceden o utilizan nuestros productos o servicios, y describe nuestra recopilación, uso y divulgación de "Datos
      personales". Los Datos personales y todos los demás términos en mayúsculas de esta sección tienen el mismo
      significado que los términos correspondientes definidos en la VCDPA o UCPA.
    </p>

    <p>
      Puede obtener más información sobre las categorías de Datos personales que tratamos, el propósito del tratamiento
      de sus Datos personales aquí y las categorías de terceros con los que divulgamos Datos personales revisando la
      sección pertinente de este aviso anterior. Podemos tratar sus Datos personales para Publicidad dirigida.
    </p>
    <p>
      Si usted es residente de Virginia o Utah, sujeto a ciertas condiciones y restricciones establecidas en la VCDPA, UCPA, y otras
      leyes aplicables, tiene los siguientes derechos con respecto a sus Datos personales:
    </p>
    <p>
      <span style="font-weight: bold;">Derecho a acceder.</span> Tiene derecho a solicitar acceso y obtener una copia
      de cualquier Dato personal que podamos tener sobre usted.
    </p>
    <p>
      <span style="font-weight: bold;">Derecho a corregir.</span> Tiene derecho a solicitar que corrijamos las
      imprecisiones de sus Datos personales.
    </p>
    <p>
      <span style="font-weight: bold;">Derecho a eliminar.</span> Tiene derecho a solicitar que eliminemos los Datos
      personales que hayamos recopilado u obtenido sobre usted.
    </p>
    <p>
      <span style="font-weight: bold;">Derecho a excluirse de la publicidad dirigida.</span> Tiene derecho a solicitar
      que suspendamos la divulgación de sus Datos personales para publicidad dirigida. Puede excluirse de la publicidad
      dirigida ajustando su configuración de cookies a través del enlace "Gestionar preferencias de cookies" (Manage
      Cookie Preferences) en el pie de página de nuestro sitio web.
    </p>
    <p>
      <span style="font-weight: bold;">Derecho a excluirse de las Ventas.</span> Tiene derecho a excluirse de las
      Ventas de sus datos personales.
    </p>
    <p>
      <span style="font-weight: bold;">Derecho a excluirse de la Elaboración de perfiles.</span> Residentes de Virginia tienen
      derecho a
      excluirse de la elaboración de perfiles en relación con decisiones que produzcan efectos jurídicos o de
      importancia similar. No elaboramos perfiles que puedan tener efectos legales o similares y, por tanto, no
      ofrecemos este derecho.
    </p>
    <p>
      <span style="font-weight: bold;">Derecho a apelar.</span> Residentes de Virginia que no están
      satisfecho con nuestras acciones
      relacionadas con el ejercicio de uno de sus derechos de privacidad anteriores, puede apelar nuestra decisión.
    </p>
    <p>
      <span style="font-weight: bold;">Cómo ejercer sus derechos.</span> Sujeto a excepciones y limitaciones en la ley aplicable,
      los residentes de Virginia o Utah pueden ejercer sus derechos de privacidad en
      virtud de la VCDPA no más de dos veces en un periodo de 12 meses. Los residentes de Virginia pueden ejercer sus
      derechos de privacidad comunicándose con nosotros al (833) 824-1685 o a través de nuestro formulario de solicitud
      en: <a [routerLink]="'/data-privacy'">https://www.radiusrecycling.com/data-privacy</a>. En
      ciertas aplicaciones, también puede acceder, eliminar y corregir ciertos Datos personales a través de sus cuentas
      en línea. Si ejerce los derechos de privacidad descritos anteriormente, no recibirá ningún trato discriminatorio
      por nuestra parte.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">VIII. Retención de datos: seguridad</p>

    <p>
      Conservamos las categorías de Información Personal descritas anteriormente durante el tiempo que sea necesario
      para los fines descritos en esta Política o autorizados por ley.
    </p>
    <p>
      Esto generalmente significa conservar cada categoría de información durante el tiempo que sea necesario, según
      cómo se ajuste la información a los siguientes criterios:
    </p>
    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        si su Información Personal es razonablemente necesaria para gestionar nuestras operaciones, para gestionar su
        relación con nosotros o para satisfacer otro fin para el que recopilamos la información;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        si su Información Personal es razonablemente necesaria para llevar a cabo un propósito revelado que sea
        razonablemente compatible con el contexto en el que se recopiló la Información Personal;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        si se requiere razonablemente que la Información Personal proteja o defienda nuestros derechos o bienes (que
        generalmente se relacionarán con las leyes aplicables que limitan las acciones en un caso particular); o
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        si las leyes o normativas aplicables nos exigen o nos permiten conservar su Información Personal.
      </li>
    </ul>

    <p>
      Cuando la Información Personal se utilice para más de un fin, podemos conservarla hasta que caduque el fin con el
      último periodo. Para obtener más información sobre nuestras políticas de conservación, póngase en contacto con
      nosotros utilizando los datos de contacto que figuran a continuación.
    </p>
    <p>
      Nos esforzamos por utilizar medidas organizativas, técnicas y administrativas razonables para proteger la
      Información Personal que mantenemos dentro de nuestra organización.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">IX. Privacidad de los informes de conducta indebida</p>
    <p>
      Esta sección complementa la sección anterior, titulada "Qué Información Personal recopila Radius y por qué la
      recopilamos", para proporcionar información adicional sobre cómo recopilamos y procesamos la información cuando
      nos informa de una posible conducta indebida. Cuando presenta una denuncia de conducta indebida, registramos la
      información que nos proporciona, lo que puede constituir la base de una investigación interna o externa sobre el
      problema que está denunciando. Si decide permanecer anónimo, la función para recopilar directamente su Información
      Personal se desactiva. Sin embargo, es posible que se conozca su identidad durante la investigación debido a la
      información que ha proporcionado.
    </p>
    <p>
      Para correos electrónicos, informes en persona o llamadas telefónicas, la información que recopilamos puede
      incluir su Información Personal, como su dirección de correo electrónico, nombre o información de identificación
      de la persona que llama.
    </p>
    <p>
      También utilizamos un sistema de terceros, Navex Global, para permitir la presentación de quejas por conducta
      indebida a través de Internet y un número de teléfono gratuito. Dichos informes pueden incluir Información
      Personal, como la dirección IP. Si decide no permanecer en el anonimato, esto también puede incluir su nombre,
      número de teléfono, dirección de correo electrónico e información sobre cómo comunicarse con usted.
    </p>
    <p>
      Cuando envía un informe a través de cualquiera de estos métodos, creamos un informe a partir de los datos que
      proporciona. Cualquier Información Personal que proporcione puede incluirse en el informe. Estos informes se
      envían a una persona designada por la empresa de acuerdo con las políticas y procedimientos de nuestra empresa.
      Según el tipo de investigación, una parte o la totalidad del informe pueden compartirse con las autoridades de los
      departamentos de Ética, Cumplimiento, Recursos Humanos, Seguridad, Auditoría Interna, Jurídico, Seguridad,
      Finanzas, Tecnología de la Información, Operaciones y otros departamentos de Radius. Además, la Información
      Personal puede compartirse con terceros, como asesores externos u otros investigadores, según sea necesario para
      investigar completamente el informe.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">X. Limitaciones de uso del sitio y visitantes internacionales</p>
    <p>
      Nuestros Sitios no están dirigidos a usuarios menores de 16 años y no recopilamos intencionadamente Información
      Personal en línea de ninguna persona que sepamos que es menor de 16 años. Tampoco vendemos ni compartimos a
      sabiendas Información Personal de personas menores de 16 años.
    </p>
    <p>
      Nuestros Sitios están diseñados para usuarios de Estados Unidos y Canadá, y son controlados y operados por
      nosotros y nuestros socios comerciales desde estos países, que pueden tener normas de protección de datos
      diferentes a las de su país.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">XI. Enlaces a otros sitios web</p>
    <p>
      Nuestros Sitios pueden proporcionar enlaces a sitios web de terceros. Cuando haga clic en uno de estos enlaces,
      accederá a contenido que no está sujeto a esta Política. No somos responsables de las prácticas de recopilación de
      información de los otros sitios web que visite y le aconsejamos que revise sus políticas de privacidad antes de
      proporcionarles cualquier Información Personal.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">XII. Cómo ponerse en contacto con nosotros</p>
    <p>
      Si tiene preguntas sobre esta Política, llámenos al (833) 824-1685 o envíenos un correo electrónico a <a href="mailto:dataprivacy@rdus.com">dataprivacy@rdus.com</a>.
    </p>
  </div>
</section>
<app-footer-sp></app-footer-sp>
