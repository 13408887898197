<app-nav-main></app-nav-main>
<section class="page-head">
  <div class="container h-100">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h1>Website Legal Notices
          <span>Website Legal Notices</span>
        </h1>
      </div>
    </div>
  </div>
  <img src="assets/images/legal-notices.jpg" alt="Website Legal Notices">
</section>
<section class="container ptb-90">
  <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
    <p>
      California residents: Click here to link directly to our <a [routerLink]="['/legal-notices']"
        fragment="privacy">Notice at Collection</a>.
    </p>
    <a href="https://www.radiusrecycling.com/data-privacy" class="btn btn-secondary">
      To make a Data Subject Privacy <br />Request, click
      here
    </a>
  </div>
  <div class="legal-notices">
    <h2 class="title-secondary">Terms Of Use</h2>

    <p>Thank you for visiting the Schnitzer Steel Industries, Inc. <i>dba</i> Radius Recycling ("Radius") website. (References to Radius include its majority- or wholly-owned subsidiaries, and references to the Radius website include all sites operated by, or on behalf of, Radius.)</p>
    <p>
      Please read these terms carefully before using this website. Using this website indicates that you accept these
      terms. Radius may change these terms at any time and your use is subject to the changes. If you do not accept
      these terms, do not use this website.
    </p>
    <p>
      The information provided on this website has been provided for your use but remains the property of Radius. Use
      or reproduction of the content or materials for any purpose not expressly permitted is prohibited. This website,
      its designs and all material contained within are copyrightunder applicable U.S. copyright laws. &#169; Radius Recycling
    </p>
    <p>
      While we have used reasonable efforts to ensure the accuracy of this website, we do not guarantee that the
      website is error-free, nor do we make any warranty or guarantee that the information is accurate, correct,
      reliable, or up-to-date.
    </p>
    <p>
      THIS WEBSITE AND THE CONTENT ON IT ARE PROVIDED "AS IS." RADIUS DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
      INCLUDING BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, OR NONINFRINGEMENT.
    </p>
    <p>
      IN NO EVENT WILL RADIUS, ITS AFFILIATES, EMPLOYEES, DIRECTORS, SHAREHOLDERS, OFFICERS, CONTRACTORS, OR AGENTS BE
      LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES (INCLUDING LOST
      PROFITS) RESULTING FROM YOUR USE OR INABILITY TO USE THIS WEBSITE, EVEN IF RADIUS HAS BEEN ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES. THE ABOVE LIMITATION SHALL APPLY WITHOUT REGARD TO THE FORM OF ACTION, WHETHER IN
      CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE.
    </p>
    <p>
      You agree to defend, indemnify, and hold Radius and any of its affiliates, employees, directors, shareholders,
      officers, contractors, or agents harmless from and against any and all claims, actions, liabilities, costs, or
      damages asserted by any third party arising from or related to your use of this website.
    </p>

    <h2 class="title-secondary mt-5" id="privacy" #privacy>Privacy Policy and Notice at Collection</h2>
    <p style="font-weight: bold;" class="tc-secondary">Last Updated: August 22, 2024</p>

    <p style="font-weight: bold;" class="tc-secondary">I. Introduction</p>

    <p>
      This Privacy Policy ("<b>Policy</b>") describes how Schnitzer Steel Industries Inc. <i>dba</i> Radius Recycling, including its majority- and wholly-owned
      subsidiaries,
      including but not limited to, Pick-n-Pull and Cascade Steel Rolling Mills, Inc. ("<b>Radius</b>," "<b>we</b>," or
      "<b>us</b>") handles personal information
      it collects online, at physical locations, by telephone, or otherwise. Unless otherwise noted, the information in
      this Policy that describes
      how and why we collected, used, and disclosed information within the past 12 months also describes the information
      we will collect and how
      we will use it. For purposes of this Policy, personal information ("<b>PI</b>") means information that identifies,
      relates to, describes, can
      reasonably be associated with, or could reasonably be linked, directly or indirectly, with you or your household.
    </p>

    <p>
      This Policy applies to interactions you may have with Radius in your individual capacity or in your capacity as
      an employee, owner, director, officer, or independent contractor of another company, partnership, sole
      proprietorship, non-profit, or government agency (collectively, "<b>Entity</b>").
    </p>
    <p>
      We may change the terms of the Policy from time to time. When we make changes, we will post the amended Policy on
      this website, <a routerLink="/legal-notices">dataprivacy.rdus.com</a>, which serves as the Privacy Policy
      and Notice at Collection for all Radius websites ("<b>Sites</b>"). If the changes are material, we will post a
      notice on this website alerting you to the change. Any changes to the Policy will become effective immediately
      upon our posting of the Policy, so please review the current Policy before providing us with PI.
    </p>

    <p style="font-weight:bold;" class="tc-secondary">
      II. What Personal Information Radius Collects and Why We Collect
      It
    </p>

    <p>
      Within the past 12 months, we collected these categories of PI (whether we collected a specific category of
      information about you depends on your interaction with us):
    </p>
    <div class="table-responsive">

      <table>
        <tr>
          <th width="40%">
            Category of Personal Information
          </th>
          <th>
            Purposes for Collection, Use, and Disclosure
          </th>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">Identifiers</span> including your real name, alias, postal address, unique
            personal identifier, online identifier, IP address, email address, account name, or other similar
            identifiers
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To communicate with you, including in response to your inquiries and to fulfill your requests
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To provide you with information about our products and services, and to provide products or services you
                have requested from us, for yourself or on behalf of an Entity you are affiliated with
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage our operations and business, such as for tax, compliance, and accounting purposes
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To conduct business with an Entity you are affiliated with
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage the security and integrity of our premises, systems, and data
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">
              Personal Information described in Cal. Civ. Code § 1798.80(e) ("Customer
              Records" information)
            </span>, including your name, signature, social security number, physical
            characteristics or description, address, telephone number, passport number, driver's license or state
            identification card number, insurance policy number, education, employment, employment history, bank account
            number, credit card number, debit card number, or other financial information, medical information, or
            health insurance information
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To communicate with you, including in response to your inquiries and to fulfill your requests
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To provide you with information about our products and services, and to provide products or services you
                have requested from us, for yourself or on behalf of an Entity you are affiliated with
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage our operations and business, such as for tax, compliance, and accounting purposes
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To conduct business with an Entity you are affiliated with
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage the security and integrity of our premises, systems, and data
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">Commercial information</span>, including records of personal property,
            products, or services purchased, obtained, or considered, or other purchasing or consuming histories or
            tendencies
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To communicate with you, including in response to your inquiries and to fulfill your requests
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To provide you with information about our products and services, and to provide products or services you
                have requested from us for yourself or on behalf of an Entity
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage our operations and business, such as for tax, compliance, and accounting purposes
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To conduct business with an Entity you are affiliated with
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage the security and integrity of our premises, systems, and data
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">Internet or other electronic network activity information</span>, including
            browsing history, search history, and information regarding your interactions with our Sites, mobile
            applications, or advertisements, including hardware and browser information of your computer or other online
            device
            <br /><br />
            See Information We Collect Automatically Online, below, for additional information
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To communicate with you, including in response to your inquiries and to fulfill your requests
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To provide you with information about our products and services, and to provide products or services you
                have requested from us for yourself or on behalf of an Entity
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage our operations and business, such as for tax, compliance, and accounting purposes
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To conduct business with an Entity you are affiliated with
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage the security and integrity of our premises, systems, and data
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">Geolocation data</span>, including location data inferred from your device
            IP address when you visit our Sites
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To communicate with you, including in response to your inquiries and to fulfill your requests
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To provide you with information about our products and services, and to provide products or services you
                have requested from us for yourself or on behalf of an Entity
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To conduct business with an Entity you are affiliated with
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage the security and integrity of our premises, systems, and data
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">Biometric information</span>, such as fingerprints
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage the security and integrity of our premises, systems, and data
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To comply with applicable laws and regulations
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">Audio, electronic, visual, thermal, or similar sensory information</span>,
            including video surveillance and call-center recordings
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage the security and integrity of our premises, systems, and data
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To comply with applicable laws and regulations
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage job duties, evaluate performance, and operate our business
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">Professional or employment-related information</span> including current or
            past job history or information about the Entity you are affiliated with and your role at that Entity, if
            you interact with us as a representative of an Entity
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To communicate with you, including in response to your inquiries and to fulfill your requests
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To provide you with information about our products and services, and to provide products or services you
                have requested from us for yourself or on behalf of an Entity
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To evaluate creditworthiness or conduct compliance checks in connection an Entity you are affiliated
                with
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To provide other products or services you have requested from us on behalf of an Entity
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To conduct business with an Entity you are affiliated with
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <span style="font-weight: bold;">Inferences drawn from other PI</span> and used to create a profile
            reflecting your preferences, characteristics, psychological trends, predispositions, behavior, attitudes,
            intelligence, abilities, and aptitudes.
          </td>
          <td>
            <ul>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To provide you with information about our products and services, and to provide products or services you
                have requested from us for yourself or on behalf of an Entity
              </li>
              <li>
                <i class="fa-solid fa-arrow-right"></i>
                To manage the security and integrity of our premises, systems, and data
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
    <p>&nbsp;</p>
    <p>
      We may also collect, use, or disclose the personal information described above for other purposes authorized by
      applicable laws, including:
    </p>

    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        to conduct internal data analysis and product development;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        to comply with law enforcement requests or legal process;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        to detect, investigate, prevent, or otherwise address fraud or other security and integrity issues, or to
        maintain the security and integrity of our products and services, including investigating and responding to
        misconduct complaints (see the Misconduct Reporting Privacy section, below, for additional information on how we
        collect and use information related to misconduct complaints);
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        to exercise or defend against legal claims, including investigating those claims;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        to comply with applicable laws and regulations; or
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        to form part of a corporate transaction or proceeding such as a merger, financing, acquisition, bankruptcy,
        dissolution, or transfer, divestiture, or sale of all or a portion of our business or assets.
      </li>
    </ul>

    <p style="font-weight: bold;" class="tc-secondary">III. Sources of Personal Information</p>

    <p>
      Radius collects PI about you when you actively provide it to us, such as by completing an online form, signing up
      for a product or service we provide, responding to a request for information, signing up to receive communications
      from us, sending us an email or letter, completing a sign-in sheet or form at one of our facilities (including
      electronically), or providing information to sell materials for recycling, an end-of-life vehicle, or other
      products or services to us. We also may collect PI about you from other sources such as our business partners and
      affiliates; the Internet or publicly available sources, including social media websites; the press or other print
      media; parties to corporate transactions; service providers and suppliers; and other organizations or individuals
      as permitted under applicable law.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">IV. Information We Collect Automatically Online</p>

    <p>When you visit any of our Sites, we may collect the following information automatically:</p>

    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        Technical information, including the internet protocol (IP) address used to connect your computer to the
        internet, your login information for our Sites, browser type and version, time zone setting, browser language
        setting, browser plug-in types and versions, operating system, and platform; and
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        Information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and
        from the Site (including date and time), products you viewed or searched for, page response times, download
        errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and
        mouse-overs), and methods used to browse away from the page.
      </li>
    </ul>

    <p>
      We may obtain this information via server logs, cookies (small data files your web browser stores on your hard
      drive), web beacons (small electronic images used to count web page views), or other commonly used mechanisms.
    </p>

    <p>
      Our Sites use cookies to distinguish you as a Site user from other users of our Sites. This helps us to provide
      you with a good experience when you browse our Sites and allows us to improve the Sites. Every time you return to
      a Site you previously visited, the browser retrieves the cookie and sends it to the Site's server. We also use
      cookies that remember the pages you have visited and the links you have followed, which we use and may share with
      third parties to help make our Sites more relevant to your interests. And more broadly, we use cookies to see how
      many visitors are on our Sites and how visitors move around the Sites. These cookies help us improve the way the
      Sites work, for example, by ensuring that users are finding what they are looking for easily.
    </p>

    <p>
      You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or
      some cookies. However, if you use your browser settings to block all cookies, you may not be able to access all or
      parts of our Sites. To learn more about cookies, please visit <a href="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org/</a>.
    </p>

    <p>
      By using any of the Sites, you are deemed to unambiguously agree to its use of any cookies that you do not
      disable.
    </p>

    <p>
      We also use tools such as Google Analytics (which in turn uses cookies) to learn more about Site visitor
      preferences and activities when using the Sites. Google Analytics uses cookies to collect information about Site
      visitors to provide analytics services based on a user's visits to sites across the Internet and across other
      mobile applications. For further information about the collection and use of data through Google Analytics, see <a href="https://www.google.com/policies/privacy/partners/" target="_blank">www.google.com/policies/privacy/partners/</a>. Google
      offers the ability to opt out from tracking through Google Analytics cookies; to learn about this, visit: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">tools.google.com/dlpage/gaoptout</a>.
    </p>

    <p>Our Sites do not respond to a user's Do-Not-Track request.</p>

    <p style="font-weight: bold;" class="tc-secondary">V. Who We Disclose Personal Information To</p>

    <p>
      We have disclosed, or may disclose, each category of PI described above to these categories of recipients:
    </p>
    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        our service providers, whom we engage to assist us with technology support, operational support, and other forms
        of assistance, and whom we bind by contract to protect the confidentiality and security of the PI we share with
        them;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        our affiliated entities within the Radius corporate family, for legally permissible purposes;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        the proposed or actual acquiring party or assignee in the event of a proposed or actual reorganization, merger,
        sale, joint venture, assignment, transfer, or other disposition of all or any portion of our company or its
        assets;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        law enforcement agencies, courts, and government agencies when the disclosure is required or permitted by law,
        including to comply with valid legal process; or
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        other parties as we believe to be appropriate: (i) when required by applicable law, including laws outside your
        country of residence; (ii) to comply with legal process; (iii) to respond to requests from public and government
        authorities; (iv) to enforce the terms and conditions for use of the Sites, including this Policy; (v) to
        protect and defend our rights and property; (vi) to protect the interests of Radius or others; and (vii) to
        permit us to pursue available remedies or limit the damages that we may sustain.
      </li>
    </ul>

    <p>
      We allow certain companies to place tracking technologies on our Sites, which allow those companies to receive
      information about your activity on our Sites that is associated with your browser or device. This information may
      include shared identifiers, commercial information, location information, and internet or other electronic network
      activity information. The companies may use that data to serve you more ads on our sites or on others' sites.
      Depending on the activity involved, this activity may be considered a sale of PI or sharing of PI for targeted
      advertising under certain state laws. Except for this kind of selling or sharing, we do not otherwise sell PI and
      do not share PI with non-affiliated entities for them to use for their own marketing purposes.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">
      VI. Additional Disclosures and Rights for Residents of California
    </p>

    <p>
      This section provides additional disclosures and describes additional rights applicable to residents of
      California under the California Consumer Privacy Act ("<span style="font-weight: bold;">CCPA</span>"), as amended.
      Capitalized terms in this section have the same meaning as the matching terms defined in the CCPA.
    </p>

    <p>You have the right not to be discriminated against for exercising your CCPA rights.</p>

    <p class="tc-secondary">
      A. <span style="text-decoration: underline;">Right to Opt Out of Sale and Sharing of Personal Information</span>
    </p>
    <p>
      You may opt out of the Sale or Sharing of your Personal Information by adjusting your Cookie Settings through the
      "Do Not Sell or Share My Personal Information" link in the footer of our website. We have also enabled the Global
      Privacy Control, which is designed to recognize opt-out preference signals. To process your request through an
      opt-out preference signal, you must use a browser or extension supporting the preference signal. If you choose to
      use an opt-out preference signal, you will need to turn it on for each browser and each device you use.
    </p>
    <p class="tc-secondary">
      B. <span style="text-decoration: underline;">Sensitive Personal Information</span>
    </p>
    <p>
      We do not collect Sensitive Personal Information for the purpose of inferring characteristics about you. We do
      not use or disclose Sensitive Personal Information for purposes to which the right to limit use and disclosure
      applies under the CCPA.
    </p>

    <p class="tc-secondary">C. <span style="text-decoration: underline;">Right to Know, Delete, and Correct</span></p>
    <p>
      The CCPA gives California Consumers certain rights. Where required, we will ask you to verify your identity
      before honoring your requests (this includes for requests made by authorized agents).
    </p>

    <p>
      <span style="font-weight: bold;">Right to Know.</span> If you are a California resident, you have the right to
      request that we provide you with the following information:
    </p>
    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        The categories and specific pieces of Personal Information we have Collected about you in the last 12 months;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        The categories of sources from which we Collect Personal Information;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        The Business or Commercial Purposes for Collecting, using, Sharing or Selling Personal Information;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        The categories of Personal Information we have disclosed about you for a Business Purpose and the categories of
        recipients to which it was disclosed; and
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        The categories of Personal Information we Sold or Shared about you and the categories of Third Parties to which
        each category of Personal Information was Sold or Shared.
      </li>
    </ul>

    <p>
      <span style="font-weight: bold;">Right to Delete.</span> You have the right to request that we delete Personal
      Information under certain circumstances, subject to several exceptions.
    </p>

    <p>
      <span style="font-weight: bold;">Right to Correct.</span> You have the right to request that we correct Personal
      Information that we maintain about you if you believe it is inaccurate. We may request documentation from you in
      connection with your request. Upon receipt of a verifiable request to correct inaccurate Personal Information, we
      will use commercially reasonable efforts to correct the Personal Information. In certain applications, you can
      also correct certain Personal Information we maintain about you by logging into your account.
    </p>

    <p class="tc-secondary">D. <span style="text-decoration: underline;">How to Submit a Request</span></p>

    <p>To exercise the rights described above, please submit a request to us by either:</p>

    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        Submitting the request through our interactive webform, at <a [routerLink]="'/data-privacy'">https://www.radiusrecycling.com/data-privacy</a>; or
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        Calling us at our data privacy toll-free number: (833) 824-1685.
      </li>
    </ul>

    <p style="font-weight: bold;" class="tc-secondary">VII. Additional Disclosures and Rights for Residents of Virginia and Utah</p>

    <p>
      This section applies to consumers in Virginia and Utah, as defined by the Virginia Consumer Data Protection Act
      ("<span style="font-weight: bold;">VCDPA</span>") or the Utah Consumer Privacy Act ("UCPA"), that access or use our
      products or services, and describes our
      collection, use, and disclosure of "Personal Data." Personal Data and all other capitalized terms in this section
      have the same meaning as the matching terms defined in the VCDPA or UCPA.
    </p>

    <p>
      You can learn about the categories of Personal Data we process, the purpose for processing your Personal Data
      here, and the categories of third parties with whom we disclose Personal Data by reviewing the relevant section of
      this notice, above. We may process your Personal Data for Targeted Advertising.
    </p>
    <p>
      If you are a resident of Virginia or Utah, subject to certain conditions and restrictions set out in the VCDPA, UCPA and other
      applicable laws, you have the following rights regarding your Personal Data:
    </p>
    <p>
      <span style="font-weight: bold;">Right to Access.</span> You have the right to request access to and obtain a
      copy of any Personal Data we may hold about you.
    </p>
    <p>
      <span style="font-weight: bold;">Right to Correct.</span> You have the right to request that we correct
      inaccuracies in your Personal Data.
    </p>
    <p>
      <span style="font-weight: bold;">Right to Delete.</span> You have the right to request that we delete Personal
      Data we have collected from or obtained about you.
    </p>
    <p>
      <span style="font-weight: bold;">Right to Opt Out of Targeted Advertising.</span> You have the right to request
      that we stop disclosures of your Personal Data for targeted advertising. You may opt out of Targeted Advertising
      by adjusting your Cookie Settings through the "Manage Cookie Preferences" link in the footer of our website.
    </p>
    <p>
      <span style="font-weight: bold;">Right to Opt Out of Sales.</span> You have the right to opt out of Sales of your
      Personal Data.
    </p>
    <p>
      <span style="font-weight: bold;">Right to Opt Out of Profiling.</span> Residents of Virginia have the right to opt out of profiling
      in furtherance of decisions that produce legal or similarly significant effects. We do not profile in a manner
      that would result in legal or similarly significant effects and as such do not offer this right.
    </p>
    <p>
      <span style="font-weight: bold;">Right to Appeal.</span> Residents of Virginia that are unsatisfied with our actions related to the
      exercise of one of your privacy rights above may appeal our decision.
    </p>
    <p>
      <span style="font-weight: bold;">How to Exercise your Rights.</span> Subject to exceptions and limitations in applicable law, residents of Virginia or Utah may exercise their privacy rights by
      contacting us at (833) 824-1685 or through our request form at: <a [routerLink]="'/data-privacy'">https://www.radiusrecycling.com/data-privacy</a>. In certain
      applications, you may also access, delete, and correct certain Personal Data through your online accounts. If you
      exercise the privacy rights described above, you will not receive discriminatory treatment from us.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">VIII. Additional Disclosure for data collected in Canada</p>

    <p>
      If you are a resident of Canada, this section applies to you.
    </p>

    <p>
      <span style="font-weight: bold;">Right to Access.</span> You have the right to request access to and obtain a copy of any personal information we may hold about you and any other information about how we use and disclose your personal information not already described in this notice.
    </p>

    <p>
      <span style="font-weight: bold;">Right to Correct.</span> You have the right to request that we correct inaccuracies in your personal information.
    </p>

    <p>
      <span style="font-weight: bold;">Right to Withdraw Consent.</span> Where we are handling your personal information based on your consent, you may withdraw your consent. Withdrawing consent may limit our ability to provide services to you.
    </p>

    <p>
      To exercise the rights described above, please submit a request to us by either:
    </p>

    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        Submitting the request through our interactive webform, at <a [routerLink]="'/data-privacy'">https://www.radiusrecycling.com/data-privacy</a>; or
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        Calling us at our data privacy toll-free number: (833) 824-1685.
      </li>
    </ul>



    <p style="font-weight: bold;" class="tc-secondary">IX. Data Retention; Security</p>

    <p>
      We keep the categories of PI described above for as long as is necessary for the purposes described in this
      Policy or otherwise authorized by law.
    </p>
    <p>
      This generally means holding each category of information for as long as necessary depending upon how the
      information fits into the following criteria:
    </p>
    <ul>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        whether your PI is reasonably necessary to manage our operations, to manage your relationship with us, or to
        satisfy another purpose for which we collected the information;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        whether your PI is reasonably necessary to carry out a disclosed purpose that is reasonably compatible with the
        context in which the PI was collected;
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        whether the PI is reasonably required to protect or defend our rights or property (which will generally relate
        to applicable laws that limit actions in a particular case); or
      </li>
      <li>
        <i class="fa-solid fa-arrow-right"></i>
        whether we are otherwise required or permitted to keep your PI by applicable laws or regulations.
      </li>
    </ul>

    <p>
      Where PI is used for more than one purpose, we may retain it until the purpose with the latest period expires.
      For more information about our retention policies, please contact us using the contact details below.
    </p>
    <p>
      We endeavor to use reasonable organizational, technical, and administrative measures to protect the PI we
      maintain within our organization.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">X. Misconduct Reporting Privacy</p>
    <p>
      This section supplements the section above, titled What Personal Information Radius Collects and Why We Collect
      It, to provide additional information on how we collect and process information when you report potential
      misconduct to us. When you file a misconduct report, we record the information you provide to us, which may form
      the basis of an internal or external investigation into the issue you are reporting. If you choose to remain
      anonymous, the feature to directly collect your PI is disabled. However, your identity may become known during the
      investigation because of the information you have provided.
    </p>
    <p>
      For emails, in-person reports, or phone calls, information we collect may include your PI such as email address,
      name, or caller-ID information.
    </p>
    <p>
      We also utilize a third-party system, Navex Global, to allow for the filing of misconduct complaints via the
      Internet and toll-free phone number. Such reports may include PI such as IP Address. If you choose to not remain
      anonymous, this may also include your name, phone number, email address, and information about communicating with
      you.
    </p>
    <p>
      When you submit a report through any of these methods we create a report from the data you provide. Any PI that
      you provide may be included in the report. These reports are submitted to a company designee according to our
      company policies and procedures. Depending on the investigation, part or all of the report may be shared with
      leaders in Ethics, Compliance, Human Resources, Security, Internal Audit, Legal, Safety, Finance, Information
      Technology, Operations, and other Radius departments. In addition, the PI may be shared with third parties, such
      as outside counsel or other investigators, as necessary to fully investigate the report.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">XI. Site Use Limitations and International Visitors</p>
    <p>
      Our Sites are not directed to users under the age of 16 and we do not knowingly collect PI online from any person
      we know to be under the age of 16. We also do not knowingly sell or share PI of individuals under the age of 16.
    </p>
    <p>
      Our Sites are designed for users from, and are controlled and operated by us and our business partners from, the
      United States and Canada, which may have different data protection rules than those of your country. When your personal information is in a jurisdiction other than your own, the courts, law enforcement and national security agencies of those jurisdiction may be able to compel access to that information.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">XII. Links to Other Websites</p>
    <p>
      Our Sites may provide links to third-party websites. When you click on one of these links, you will be accessing
      content that is not subject to this Policy. We are not responsible for the information-collection practices of the
      other websites that you visit and advise you to review their privacy policies before you provide them with any PI.
    </p>

    <p style="font-weight: bold;" class="tc-secondary">XIII. How to Contact Us</p>
    <p>
      If you have questions regarding this Policy, please call us at (833) 824-1685 or email us at <a href="mailto:dataprivacy@rdus.com">dataprivacy@rdus.com</a>.
    </p>
  </div>
</section>
<app-footer></app-footer>
