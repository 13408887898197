import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlDecider } from '../../lib/url-decider';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.css']
})

export class LocationSearchComponent implements OnInit {

  langSelector = 'eng';

  @Input() Theme = 'theme2';
  @Input() Lang = 'eng';
  
  PostalCode = '';
  Division = 'MRB,SMB';
  isPostalCode:boolean=false;
  public txt: any;
  private txtSp: any = {
    'title': 'Encuentre la ubicación de',
    'titleorange': 'Radius Recycling',
    'titlesub': 'más cercana a usted',
    'dd': ['Reciclaje de Metales', 'Reciclaje de Autos', 'Todos'],
    'search': 'Lista completa de ubicaciones',
    'pleaseEnterAddress': 'Por favor ingrese la dirección',
    'ph': 'Dirección o Código Postal', 'type': 'Tipo' }
  private txtEng: any = {
    'title': 'Find the',
    'titleorange': 'Radius Recycling',
    'titlesub': 'Location Closest to You',
    'dd': ['Metals Recycling', 'Auto Recycling', 'All'],
    'search': 'Complete Location List',
    'pleaseEnterAddress': 'Please enter address',
    'ph': 'Address or ZIP / Postal Code', 'type': 'Type' }

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {

    if (this.route.snapshot.data['lang']) {
      this.langSelector = UrlDecider.getLangFromUrl();
    }

    if (this.Lang === 'es')
      this.txt = this.txtSp;
    else
      this.txt = this.txtEng;
  }

  getLocationSearchLink() {
    const pCode=this.PostalCode.trim();
    if(pCode!=='')
      this.Lang === 'es'? this.router.navigate(['/instalaciones/buscar', { zip: this.PostalCode, div: this.Division }]): this.router.navigate(['/locations/search', { zip: this.PostalCode, div: this.Division }]);
    else
      this.isPostalCode=true;
  }
  locationLink(){
    let path:any='';
    path = this.Lang==='es'? '/instalaciones': '/locations';
    return path;
  }
}
