import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvestorEventsService {

  constructor(private http: HttpClient) {}

  public getInvestorEvents() {
    return this.http.get(window.location.origin + '/api/investorevents');
  }

  public getInvestorEventById(eventId: any) {
    return this.http.get(window.location.origin + '/api/investorevents/' + eventId);
  }

  public displayEarningsBox() {
    return this.http.get(window.location.origin + '/api/investorevents/earningsbox');
  }

}
