<div id="container" class="l-constrained">
  <app-nav-main-sp></app-nav-main-sp>
  <section class="head-vid">
    <div class="container">
      <div class="title text-white">
        <div>
          <h1>Donde empieza el cambio</h1>
        </div>
      </div>
    </div>
    <img src="assets/images/home-banners/where-change-begins-radius-recycling.jpg" alt="Radius Recycling" />
  </section>
  <section class="container ptb-90 sp-home">
    <div class="row">
      <div class="col-xl-12 pr-90 mb-xl-0">
        <div class="d-block text-center text-md-start d-md-flex justify-content-between">
          <h2 class="home-title">Mención en la lista de las empresas más influyentes de 2023 de TIME</h2>
          <div><a href="https://time.com/collection/time100-companies-2023/6285214/schnitzer-steel-industries/" target="_black"><img src="assets/images/awards/time-most-influential-companies.png"
              alt="Mención en la lista de las empresas más influyentes de  2023 de TIME" /></a></div>
        </div>
        <div class="row gird-blocks mt-5">
          <div class="col-md-6">
            <a [routerLink]="'/instalaciones'">
              <img src="assets/images/location.jpg" alt="Instalaciones" />
              <h4>Dónde estamos</h4>
            </a>
          </div>
          <div class="col-md-6 my-md-0 my-5">
            <a [routerLink]="'/lineas-de-servicio/servicios-de-reciclaje-de-vehiculos'">
              <img src="assets/images/services.jpg" alt="Servicios" />
              <h4>Servicios</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container circular-economy">
    <div class="row align-items-center">
      <div class="col-md-7">
        <div class="title text-white">
          <h1>Radius Recycling</h1>
          <span>En el centro de la economía circular</span>
        </div>
      </div>
      <div class="col-md-5 text-center">
        <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#RadiusRecyclingVidoe" id="HomeHeroVideo" class="play-btn" (click)="openModal('videoModal');playPause()"><img
            src="assets/images/icons/play-icon.png" alt="Play Video" /></a>
      </div>
    </div>
    <ngx-smart-modal #videoModal [identifier]="'videoModal'"
          customClass="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" animation="slide">
          <div class="modal-content">
          <video controls id="videoPlayer" #videoPlayer>
            <source src="assets/videos/radius-recycling-captioned-video-1080p.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        </ngx-smart-modal>
  </section>
  <section class="container ptb-90 find-location">
    <div id="find-a-location">
      <app-location-search Theme="theme1" Lang="es"></app-location-search>
    </div>
    <div>
    </div>
  </section>
  <div id="footer">
    <app-footer-sp></app-footer-sp>
  </div>
</div>
