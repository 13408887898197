<h3>{{quoteModel?.symbol}}<span style="margin-left: 5px; color: #005dab">{{quoteModel?.lastTrade | number : '1.2-2' : 'en-US'}}</span></h3>
<h4 [className]="(quoteModel?.changeNumber >= 0) ? 'stock_up' : 'stock_down'">{{quoteModel?.changeNumber | number : '1.2-2' : 'en-US' }} ({{quoteModel?.changePercent | number : '1.2-2' : 'en-US' }}%)</h4>
<p class="tight">
  {{quoteModel?.exchange}}
  <span class="is-hidden-mobile">
    <br>
    {{quoteModel?.date | date:'MM/dd/yyyy'}}<br>
    {{quoteModel?.date | date:'shortTime'}} ET<br>
    20 minute delay
  </span>
</p>

