import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvestorNewsService {

  constructor(private http: HttpClient) { }

  public GetNews(page: any) {
    return this.http.get(window.location.origin + '/api/investornews/' + page);
  }

  public GetCounts(page: any) {
    return this.http.get(window.location.origin + '/api/investornews/counts/' + page);
  }

  public GetNewsDetails(id: any) {
    return this.http.get(window.location.origin + '/api/investornews/detail/' + id);
  }

  public GetNewsReleasePreview(id: any) {
    return this.http.get(window.location.origin + '/api/newsreleasepreview/' + id);
  }

  public GetFilingsDO(page: any) {
    return this.http.get(window.location.origin + '/api/investordofilings/' + page);
  }

  public GetFilingsCompany(page: any) {
    return this.http.get(window.location.origin + '/api/investorcompanyfilings/' + page);
  }


}
