import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component'
import { HomeSpComponent } from './home-sp/home-sp.component'
import { SitemapComponent } from './sitemap/sitemap.component';
import { LegalNoticesComponent } from './legal-notices/legal-notices.component';
import { LegalNoticesSpComponent } from './legal-notices-sp/legal-notices-sp.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { MapaDelSitioComponent } from './mapa-del-sitio/mapa-del-sitio.component';
import { UrlDecider } from './shared/lib/url-decider';
import { CeoOfficeHoursComponent } from './ceo-office-hours/ceo-office-hours.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const lang = UrlDecider.getLangFromUrl();

const routes: Routes = (lang === 'eng') ? [
  { path: '', component: HomeComponent },
  { path: 'eng', component: HomeComponent },
  { path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule), data: { title: 'Company' } },
  { path: 'locations', loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule), data: { title: 'Locations' } },
  { path: 'sell-to-us', loadChildren: () => import('./sell-to-us/sell-to-us.module').then(m => m.SellToUsModule), data: { title: 'Sell to us' } },
  { path: 'buy-from-us', loadChildren: () => import('./buy-from-us/buy-from-us.module').then(m => m.BuyFromUsModule), data: { title: 'Buy from us' } },
  { path: 'service-lines', loadChildren: () => import('./service-lines/service-lines.module').then(m => m.ServiceLinesModule), data: { title: 'Services' } },
  { path: 'legal-notices', component: LegalNoticesComponent, data: { title: 'Website Legal Notices' } },
  { path: 'avisos-legales', component: LegalNoticesSpComponent, data: { title: 'Website Legal Notices' } },
  { path: 'data-privacy', component: DataPrivacyComponent },
  { path: 'ceo-office-hours', component: CeoOfficeHoursComponent },
  { path: 'sitemap', component: SitemapComponent },
  {path:'page-not-found', component:PageNotFoundComponent, data:{title:'404 - Page Not Found'}},
  {path:'**', redirectTo:'/page-not-found'},
  { path: 'eng', component: HomeComponent },
] : [
  { path: '', component: HomeSpComponent},
  { path: 'es', component: HomeSpComponent},
  { path: 'mapa-del-sitio', component: MapaDelSitioComponent, data: { title: 'Mapa Del Sitio' } },
  { path: 'compania', loadChildren: () => import('./company-sp/company-sp.module').then(m => m.CompanySpModule), data: { title: 'Compania' } },
  { path: 'instalaciones', loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule), data: { title: 'Instalaciones' } },
  { path: 'vendanos-su-chatarra', loadChildren: () => import('./sell-to-us-sp/sell-to-us-sp.module').then(m => m.SellToUsSpModule), data: { title: 'Vendanos su chatarra' } },
  { path: 'lineas-de-servicio', loadChildren: () => import('./service-lines-sp/service-lines-sp.module').then(m => m.ServiceLinesSpModule), data: { title: 'Líneas de Servicio' } }, { path: 'legal-notices', component: LegalNoticesComponent, data: { title: 'Website Legal Notices' } },
  { path: 'legal-notices', component: LegalNoticesComponent, data: { title: 'Website Legal Notices' } },
  { path: 'avisos-legales', component: LegalNoticesSpComponent, data: { title: 'Website Legal Notices' } },
  //{ path: 'legal-notices', component: LegalNoticesSpComponent, data: { title: 'Website Legal Notices' } },
  {path:'pagina-no-encontrada', component:PageNotFoundComponent, data:{title:'404 - página no encontrada'}},
  {path:'**', redirectTo:'/pagina-no-encontrada'},
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
