import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { UrlDecider } from '../../shared/lib/url-decider';
const lang = UrlDecider.getLangFromUrl();

@Injectable({
  providedIn: 'root'
})
export class HreflangService {

  constructor(@Inject(DOCUMENT) private doc: any) { }

  createLinkHreflang(altUrl: string) {
    const enLink: HTMLLinkElement = this.doc.createElement('link');
    enLink.setAttribute('rel', 'alternate');
    enLink.setAttribute('href', this.doc.URL);
    enLink.setAttribute('hreflang', lang);
    this.doc.head.appendChild(enLink);

    const esLink: HTMLLinkElement = this.doc.createElement('link');
    esLink.setAttribute('rel', 'alternate');
    if (lang === 'eng') {
      esLink.setAttribute('href', environment.languageSettings.esHost + altUrl);
    } else {
      esLink.setAttribute('href', environment.languageSettings.engHost + altUrl);
    }
    esLink.setAttribute('href', environment.languageSettings.esHost + altUrl);
    esLink.setAttribute('hreflang', lang === 'es' ? 'en' : 'es');
    this.doc.head.appendChild(esLink);

    const defLink: HTMLLinkElement = this.doc.createElement('link');
    defLink.setAttribute('rel', 'alternate');
    if (lang === 'eng') {
      defLink.setAttribute('href', this.doc.URL);
    } else {
      defLink.setAttribute('href', environment.languageSettings.engHost + altUrl);
    }
    defLink.setAttribute('hreflang', 'x-default');
    this.doc.head.appendChild(defLink);
  }
}
