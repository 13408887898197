<div *ngIf="isSubmitted">
  <div class="">
    <div class="alert alert-primary" role="alert">
      Your request was successfully submitted.
    </div>
  </div>
</div>
<div *ngIf="!isSubmitted">
  <h2 class="title-secondary">Contact Our Team</h2>
  <form *ngIf="serviceLinesForm" [formGroup]="serviceLinesForm" (ngSubmit)="onSubmit()" id="serviceLinesForm">
    <div class="contact-our-team">
      <div class="row">
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="firstNameLabel" class="weight-400">First Name&thinsp;*</label>
            <input maxlength="40" id="firstName" formControlName="firstName" class="form-control" />
            <div *ngIf="(firstNameValid.invalid && firstNameValid.touched)" class="alert alert-danger">
              <div *ngIf="firstNameValid.errors?.required || firstNameValid.touched">
                Please provide a first name
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="lastNameLabel">Last Name&thinsp;*</label>
            <input maxlength="40" id="lastName" formControlName="lastName" class="form-control" />
            <div *ngIf="(lastNameValid.invalid && lastNameValid.touched)" class="alert alert-danger">
              <div *ngIf="lastNameValid.errors?.required || lastNameValid.touched">
                Please provide a last name
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="emailLabel">Email&thinsp;*</label>
            <input type="email" maxlength="80" id="email" formControlName="email" class="form-control" />
            <div *ngIf="(emailValid.invalid && emailValid.touched)" class="alert alert-danger">
              <div *ngIf="emailValid.errors?.required || emailValid.touched">
                Please provide a valid email address
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="companyLabel">Company&thinsp;*</label>
            <input maxlength="40" id="company" formControlName="company" class="form-control" />
            <div *ngIf="(companyValid.invalid && companyValid.touched)" class="alert alert-danger">
              <div *ngIf="companyValid.errors?.required || companyValid.touched">
                Please provide a company
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="countryLabel">Country</label>
            <select id="country" formControlName="country" class="form-control state-field form-select"
                    (change)="selectCountry($event)">
              <option *ngFor="let c of country; let i = index" [value]="country[i].code">{{country[i].name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="stateLabel">State</label>
            <select id="state" formControlName="state" class="form-control state-field form-select">
              <option *ngFor="let s of state; let i = index" [value]="state[i].code">{{state[i].name}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="sl-form-group">
            <label id="phoneLabel">Phone Number</label>
            <input maxlength="40" id="phone" formControlName="phone" class="form-control" />
          </div>
        </div>
        <div class="col-md-6" id="infoNotesGroup">
          <div class="sl-form-group">
            <label id="infoNotesLabel">Additional information</label>
            <input type="text" id="infoNotes" class="form-control" #infoNotes />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12" id="recaptchaElem">
          <ngx-recaptcha2 #captchaElem theme="white" hl="en" siteKey="{{siteKey}}" formControlName="recaptcha"
                          id="recaptcha" (success)="handleSuccess($event)" (error)="handleError()"
                          (expire)="handleExpire()"></ngx-recaptcha2>
        </div>
      </div>

      <p>*&thinsp;Indicates required field.</p>

      <div class="row">
        <div class="col-12 text-center mb-0">
          <button type="submit" id="submitButton"
                  [className]="(serviceLinesForm.valid && isRecaptchaValid) ? 'btn btn-secondary sbutton-disabled' : 'btn btn-secondary sbutton-disabled'"
                  [disabled]="!serviceLinesForm.valid || !isRecaptchaValid">
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
