<header>
  <div class="top-nav container">
    <ul>
      <li><a [routerLink]="'/compania/carreras'" (click)="isNavbarOpen=false">Carreras profesionales</a></li>
      <li><a [routerLink]="'/compania/contactos'" (click)="isNavbarOpen=false">Contáctenos</a></li>
      <li class="is-last"><a href="{{linkEnglish}}">English</a></li>
    </ul>
  </div>
  <nav class="navbar sp navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="'/'"><img src="assets/images/logo.svg" alt="" /></a>
      <button class="navbar-toggler" type="button" (click)="isNavbarOpen=!isNavbarOpen">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngClass]="{ 'show': isNavbarOpen }">
        <ul class="navbar-nav ms-auto mb-lg-0">
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" (click)="onMenuToggle('isAboutUsMenu')">Sobre
              nosotras</a>
            <ul class="dropdown-menu" [ngClass]="{ 'show': navMenuToggle.isAboutUsMenu }">
              <li><a [routerLink]="'/compania'" (click)="afterMenuToggle('isAboutUsMenu')">Nuestra empresa</a></li>
              <li><a [routerLink]="'/compania/cultura'" (click)="afterMenuToggle('isAboutUsMenu')">Cultura</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link" [routerLink]="'/instalaciones'">Dónde estamos</a>
          </li>
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle"
            (click)="onMenuToggle('isSellToUsMenu')">Vendános Su Chatarra</a>
            <ul class="dropdown-menu" [ngClass]="{ 'show': navMenuToggle.isSellToUsMenu }">
              <li><a [routerLink]="'/vendanos-su-chatarra'" (click)="afterMenuToggle('isSellToUsMenu')">Materiales aceptados</a></li>
              <li><a [routerLink]="'/instalaciones'" (click)="afterMenuToggle('isSellToUsMenu')">Nuestras Instalaciones</a></li>
            </ul>
          </li>
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle"
              (click)="onMenuToggle('isServicesMenu')">Servicios</a>
            <ul class="dropdown-menu dropdown-menu-end" [ngClass]="{ 'show': navMenuToggle.isServicesMenu }">
              <li class="dropdown">
                <a [routerLink]="'/lineas-de-servicio/servicios-de-reciclaje-de-vehiculos'" (click)="afterMenuToggle('isServicesMenu')">3PR<sup>TM</sup> Reciclaje
                  de Terceros</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
