import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  constructor(private http: HttpClient) {  }

  postLog(logLevel: string, logEntry: string) {

    const postData = { 'logLevel': logLevel, 'logEntry': logEntry };

    return this.http.post(window.location.origin + '/api/log', JSON.stringify(postData), this.httpOptions);

  }

}
