import { Component, OnInit, ViewEncapsulation,ViewChild, ElementRef } from '@angular/core';
import { UrlDecider } from '../shared/lib/url-decider';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-home-sp',
  templateUrl: './home-sp.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./home-sp.component.css']
})
export class HomeSpComponent implements OnInit {

  engURLRoot: any;
  @ViewChild("videoPlayer", { static: true }) videoplayer!: ElementRef;
  constructor(public modalService: NgxSmartModalService) {
    this.engURLRoot = UrlDecider.getEnglishURL();

  }

  ngOnInit() {

  }
  playPause() {
    const video =this.videoplayer.nativeElement;
    video.currentTime=0;
    video.load();
    video.play();
  }
  openModal(name:string){
    this.modalService.getModal(name).open()
  }
}
