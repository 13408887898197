import { Component, OnInit } from '@angular/core';
import { UrlDecider } from '../shared/lib/url-decider';
import { Router } from '@angular/router';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  langSelector='eng';
  data:any={
    header:'Page Not Found',
    p1:'We are so sorry, we can\'t find what you are looking for.',
    p2:'Please visit our',
    sitemapLinkName:'Site map',
    homeButton:'Back to Home'}
  constructor(private router: Router){if (UrlDecider.getLangFromUrl()) { this.langSelector = UrlDecider.getLangFromUrl();}}
  ngOnInit(): void {
    if(this.langSelector==='es'){
      this.data={
        header:'Página no encontrada',
        p1:'Lo sentimos mucho, no encontramos lo que buscas.',
        p2:'Por favor visite nuestra',
        sitemapLinkName:'Mapa Del Sitio',
        homeButton:'De vuelta a casa'}
    }
  }
  onLinkClick(){
    if(this.langSelector==='es')
      return this.router.navigate(['/mapa-del-sitio'])
    else 
      return this.router.navigate(['/sitemap'])

  }
}
