import { AfterViewChecked, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, NavigationError, ActivationStart } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { EnvironmentService } from './shared/service/environment.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {
  @ViewChild('banner') banner: ElementRef;
  title = 'ClientApp';
  env: any;
  devRouteError: string | null = null;

  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: 'f966ecd0-cc2f-4d26-8a17-ecc2d87aed9e',
      isCookieUseDisabled: true,
      isStorageUseDisabled: true,
      enableSessionStorageBuffer: true
    } // if insights stays this needs to go to the environment.ts
  });

  constructor(
    private route: Router,
    private titleRef: Title,
    private environmentService: EnvironmentService,
    private ren: Renderer2,
    @Inject(DOCUMENT) private dom: any) {

    this.subscribeToRouterEvents();
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  ngOnInit() {

    this.loadEnvironment();
    this.loadScripts();

    // this.addOneTrustScript();
    this.addGoogleAnalyticsScript(environment.googleAnalyticsKey);
  }

  ngAfterViewChecked() {
    this.checkLocalStorage();
  }

  subscribeToRouterEvents() {

    this.route.events.pipe(filter((event: any) => event instanceof NavigationError)).subscribe((navEvent) => {
      const evt = navEvent as unknown as NavigationError;
      if (environment.production) {
        window.location.href = window.location.origin + '/PageNotFound?l=' + evt.url;
      } else {
        this.devRouteError = evt.error;
      }

    });

    this.route.events.pipe(filter((event: any) => event instanceof ActivationStart)).subscribe((event: any) => {
      const evt = event as unknown as any;
      const ts = evt?.snapshot?.data?.title ? 'Radius Recycling | ' + evt?.snapshot?.data?.title : 'Radius Recycling';
      this.titleRef.setTitle(ts);
    });
  }

  loadEnvironment() {
    this.environmentService.getEnvironment().pipe(first()).subscribe((data: any) => {
      this.env = data;
      this.env = this.env.toLowerCase();
    });
  }

  checkLocalStorage() {
    const dismiss = sessionStorage.getItem('dismiss');
    const localBanner = document.getElementById('localBanner');
    const devBanner = document.getElementById('devBanner');
    const qaBanner = document.getElementById('qaBanner');

    if (dismiss === 'true') {
      if (localBanner) {
        localBanner.style.display = 'none';
      }
      if (devBanner) {
        devBanner.style.display = 'none';
      }
      if (qaBanner) {
        qaBanner.style.display = 'none';
      }
    }
  }

  dismissBanner() {
    if (this.banner) {
      this.ren.setStyle(this.banner.nativeElement, 'display', 'none');
    }
    sessionStorage.setItem('dismiss', 'true');
  }

  loadScripts() {
    const node1 = document.createElement('script');
    node1.src = 'assets/js/SearchSchema.js';
    node1.type = 'text/javascript';
    node1.async = true;
    node1.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node1);
  }

  addGoogleAnalyticsScript(key: any) {
    const head = this.dom.getElementsByTagName('head')[0];

    const script: HTMLScriptElement = this.dom.createElement('script');

    script.type = 'text/javascript';

    script.innerHTML = ' var _gaq = _gaq || [];' +
      '_gaq.push([\'_setAccount\', \'' + key + '\']);' +
      '_gaq.push([\'_trackPageview\']);' +

      '(function () {' +
      'var ga = document.createElement(\'script\'); ga.type = \'text/plain\'; ga.async = true; ga.id=\'gaScript\';' +
      'ga.src = (\'https:\' === document.location.protocol ? \'https://ssl\' : \'http://www\') + \'.google-analytics.com/ga.js\';' +
      'var s = document.getElementsByTagName(\'meta\')[0]; s.parentNode.insertBefore(ga, s);' +
      '})();';

    head.appendChild(script);
    const el = document.getElementById('gaScript');
    if (el) {
      el.className = 'optanon-category-C0004';
    }
  }


  // addOneTrustScript() {
  //   const script2: HTMLScriptElement = this.dom.createElement('script');
  //   script2.type = 'text/javascript';
  //   script2.innerHTML = '(function () {\n' +
  //     'var ot = document.createElement(\'script\'); ot.type = \'text/javascript\';\n' +
  //     'ot.src = \'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js\';\n' +
  //     'ot.charset = \'UTF-8\';\n' +
  //     'ot.setAttribute(\'data-domain-script\',\'3e7c9f88-6495-407b-a590-2da577e34c0f\');\n' +
  //     'var s = document.getElementsByTagName(\'meta\')[0];\n' +
  //     's.parentNode.insertBefore(ot, s); \n' +
  //     '})();\n';
  //   const head = this.dom.getElementsByTagName('head')[0];
  //   head.appendChild(script2);

  //   const script: HTMLScriptElement = this.dom.createElement('script');
  //   script.type = 'text/javascript';
  //   script.innerHTML = '\nfunction OptanonWrapper() { }\n';
  //   const metaTag = document.getElementsByTagName('meta')[0];
  //   metaTag?.parentNode?.insertBefore?.(script, metaTag);
  // }

  //<!--OneTrust Cookies Consent Notice start for schnitzersteel.com -- >
  //addOneTrustScript() {
  //  const script2: HTMLScriptElement = this.dom.createElement('script');
  //  script2.type = 'text/javascript';
  //  script2.innerHTML = '(function () {\n' +
  //    'var ot = document.createElement(\'script\'); ot.type = \'text/javascript\';\n' +
  //    'ot.src = \'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js\';\n' +
  //    'ot.charset = \'UTF-8\';\n' +
  //    'ot.setAttribute(\'data-domain-script\',\'64ea63aa-9c4f-4451-abaa-27d60f28024c\');\n' +
  //    'var ot2 = document.createElement(\'script\'); ot2.type = \'text/javascript\'; \n' +
  //    'ot2.src = \'https://cdn.cookielaw.org/consent/64ea63aa-9c4f-4451-abaa-27d60f28024c/OtAutoBlock.js\';\n' +
  //    'var s = document.getElementsByTagName(\'meta\')[0];\n' +
  //    's.parentNode.insertBefore(ot2, s); \n' +
  //    's.parentNode.insertBefore(ot, s); \n' +
  //    '})();\n';
  //  const head = this.dom.getElementsByTagName('head')[0];
  //  head.appendChild(script2);

  //  const script: HTMLScriptElement = this.dom.createElement('script');
  //  script.type = 'text/javascript';
  //  script.innerHTML = '\nfunction OptanonWrapper() { }\n';
  //  const metaTag = document.getElementsByTagName('meta')[0];
  //  metaTag?.parentNode?.insertBefore?.(script, metaTag);
  //}
  
}
