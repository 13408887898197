<app-nav-main-sp></app-nav-main-sp>
<section class="page-head">
  <div class="container h-100">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h1>Mapa Del Sitio
          <span>Mapa Del Sitio</span>
        </h1>
      </div>
    </div>
  </div>
  <img src="assets/images/site-map/sitemap.jpg" alt="Mapa Del Sitio">
</section>
<section class="ptb-90 site-map">
  <div class="container">
    <div class="row">
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Sobre nosotras</h2>
          <img src="assets/images/company/our-company.jpg" alt="Sobre nosotras">
        </div>
        <ul>
          <li><a [routerLink]="'/compania'"><i class="fa-solid fa-arrow-right"></i> Nuestra empresa</a></li>
          <li><a [routerLink]="'/compania/cultura'"><i class="fa-solid fa-arrow-right"></i> Cultura</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Dónde estamos</h2>
          <img src="assets/images/locations.jpg" alt="Locations">
        </div>
        <ul>
          <li><a [routerLink]="'/instalaciones'"><i class="fa-solid fa-arrow-right"></i> Nuestra ubicación</a></li>
          <li><a [routerLink]="'/instalaciones/buscar'"><i class="fa-solid fa-arrow-right"></i> Encuentre una Instalación</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Véndanos</h2>
          <img src="assets/images/sell-to-us/sell-to-us.jpg" alt="Véndanos">
        </div>
        <ul>
          <li><a [routerLink]="'/vendanos-su-chatarra'"><i class="fa-solid fa-arrow-right"></i> Véndanos</a></li>
          <li><a [routerLink]="'/vendanos-su-chatarra/materiales-que-compramos'"><i class="fa-solid fa-arrow-right"></i> Materiales aceptados</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Servicios</h2>
          <img src="assets/images/services/services.jpg" alt="Servicios">
        </div>
        <ul>
          <li><a [routerLink]="'/lineas-de-servicio/servicios-de-reciclaje-de-vehiculos'"><i class="fa-solid fa-arrow-right"></i>3PR<sup>TM</sup> Reciclaje de Terceros</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Carreras profesionales</h2>
          <img src="assets/images/careers/careers.jpg" alt="Carreras profesionales">
        </div>
        <ul>
          <li><a [routerLink]="'/compania/carreras'"><i class="fa-solid fa-arrow-right"></i> Descripción general</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Contáctenos</h2>
          <img src="assets/images/company/contact-us.jpg" alt="Contáctenos">
        </div>
        <ul>
          <li><a [routerLink]="'/compania/contactos'"><i class="fa-solid fa-arrow-right"></i> Contáctenos</a></li>
        </ul>
      </div>
      </div>
      <div class="col-md-4 mt-md-0 mt-4">
        <div class="shadow h-100">
        <div class="head">
          <h2>Ética Y Código De Conducta</h2>
          <img src="assets/images/ethics/ethics-code-of-conduct.jpg" alt="Ética Y Código De Conducta">
        </div>
        <ul>
          <li><a [routerLink]="'/compania/etica'"><i class="fa-solid fa-arrow-right"></i> Ética</a></li>
        </ul>
      </div>
      </div>
    </div>
  </div>
</section>
<app-footer-sp></app-footer-sp>
